import { Theme } from '@mui/material';

export default {
  defaultProps: {
    maxWidth: false as const,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      padding: `0 ${theme.spacing(1.5)}`,
      [theme.breakpoints.up('xs')]: {
        padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
      },
      [theme.breakpoints.up('sm')]: {
        padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
      },
    }),
  },
};
