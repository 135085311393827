import {
  ActionType,
  ChangeGroup,
  Changelog,
  ScorecardAuditAction,
  UMChangelog,
  UserManagementAction,
} from 'store/interfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Stack } from '@mui/material';
import { resizeImage } from 'helpers';
import {
  Cell,
  ChangelogItemContainer,
  MarginWrapper,
} from './ChangelogDialog.styled';
import ScorecardUpdateChangelog from './ScorecardUpdateChangelog.component';
import StatusUpdateChangelog from './StatusUpdateChangelog.component';
import ProjectChangelogComponent from './ProjectChangelog.component';
import FeedbackRequestChangelog from './FeedbackRequestChangelog.component';
import FeedbackModerateChangelog from './FeedbackModerateChangelog.component';
import FeedbackUpdateChangelog from './FeedbackUpdateChangelog.component';
import ChangelogItemErrorBoundary from './ChangelogItemErrorBoundary.component';
import UserRolesUpdateChangelog from './UserRolesUpdateChangelog';

const renderDescription = (
  actionTypeAlias: ActionType['alias'],
  changeGroups: ChangeGroup[],
  props: {
    user?: UMChangelog['user'];
    role?: UMChangelog['role'];
  },
) => {
  switch (actionTypeAlias) {
    case ScorecardAuditAction.CREATED_FROM_TEMPLATE:
      return null;
    case ScorecardAuditAction.UPDATED:
      return <ScorecardUpdateChangelog changeGroups={changeGroups} />;
    case ScorecardAuditAction.GOALS_APPROVED:
    case ScorecardAuditAction.ASSESSMENT_REQUESTED:
    case ScorecardAuditAction.ASSESSMENT_REVIEW:
    case ScorecardAuditAction.GOALS_CONFIRMED:
    case ScorecardAuditAction.GOALS_REDRAFTED:
    case ScorecardAuditAction.GOALS_REVERTED_TO_ACTIVE:
    case ScorecardAuditAction.ASSESSMENT_COMPLETED:
    case ScorecardAuditAction.ASSESSMENT_COLLECTED:
    case ScorecardAuditAction.ASSESSMENT_RE_REQUESTED:
      return <StatusUpdateChangelog changeGroups={changeGroups} />;
    case ScorecardAuditAction.CUSAT_PROJECT_UNASSIGNED:
    case ScorecardAuditAction.CUSAT_PROJECT_ASSIGNED:
      return <ProjectChangelogComponent changeGroups={changeGroups} />;
    case ScorecardAuditAction.FEEDBACK_REQUEST_BULK_UPDATED:
      return <FeedbackRequestChangelog changeGroups={changeGroups} />;
    case ScorecardAuditAction.FEEDBACK_MODERATED:
      return <FeedbackModerateChangelog changeGroups={changeGroups} />;
    case ScorecardAuditAction.FEEDBACK_UPDATED:
    case ScorecardAuditAction.FEEDBACK_PROVIDED:
      return <FeedbackUpdateChangelog changeGroups={changeGroups} />;
    case UserManagementAction.USER_ROLES_UPDATED:
      return (
        <UserRolesUpdateChangelog
          changeGroups={changeGroups}
          user={props.user}
          role={props.role}
        />
      );
    default:
      return null;
  }
};

export default function ChangelogItem({
  doneAt,
  actor,
  actionType,
  changeGroups,
  ...restCLProps
}: Changelog | UMChangelog) {
  const { formatDate } = useIntl();

  // If any details weren't provided for feedback updated type -  just not show a record.
  if (
    actionType.alias === ScorecardAuditAction.FEEDBACK_MODERATED &&
    !changeGroups[0]
  ) {
    return null;
  }

  return (
    <ChangelogItemErrorBoundary>
      <ChangelogItemContainer>
        <Cell width="14%">
          <MarginWrapper>
            <FormattedMessage
              id="pages.scorecards.updatedDate"
              defaultMessage="{date} at {hour}"
              values={{
                date: formatDate(doneAt, {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }),
                hour: formatDate(doneAt, {
                  hour: 'numeric',
                  minute: 'numeric',
                }),
              }}
            />
          </MarginWrapper>
        </Cell>
        <Cell width="14%">
          {actor ? (
            <Stack direction="row" alignItems="center">
              <Avatar
                alt={actor.fullName}
                src={resizeImage(actor.avatar, 30)}
                sx={{ marginRight: '8px', width: '30px', height: '30px' }}
              />
              {actor.fullName}
            </Stack>
          ) : (
            <MarginWrapper mb="9px">
              <FormattedMessage
                id="pages.scorecards.automaticEvent"
                defaultMessage="Automatic event by MARS system"
              />
            </MarginWrapper>
          )}
        </Cell>
        <Cell width="19%">
          <MarginWrapper>{actionType.description}</MarginWrapper>
        </Cell>
        <Cell width="48%" whiteSpace="pre-wrap">
          <MarginWrapper>
            {renderDescription(actionType.alias, changeGroups, restCLProps)}
          </MarginWrapper>
        </Cell>
      </ChangelogItemContainer>
    </ChangelogItemErrorBoundary>
  );
}
