import { Box } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { useIntl } from 'react-intl';
import { SupportFeedbackIcon } from 'components/Icons';
import { useSelector } from 'react-redux';
import { selectFeedbackDialogState, toggleFeedbackDialog } from 'store/profile';
import { useAppDispatch } from 'store';
import { useCallback } from 'react';
import SupportFeedbackDialogComponent from './SupportFeedbackDialog.component';
import { SupportFeedbackIconButton } from './SupportFeedback.styled';
import { DialogOnClose } from '../Dialog';

export default function SupportFeedbackButton() {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const open = useSelector(selectFeedbackDialogState);
  const onOpen = useCallback(
    () => dispatch(toggleFeedbackDialog(true)),
    [dispatch],
  );
  const onClose = useCallback<DialogOnClose>(() => {
    dispatch(toggleFeedbackDialog(false));
  }, [dispatch]);

  return (
    <Box mr={2.5}>
      <Tooltip
        title={formatMessage({
          id: 'supportFeedback.sendFeedback',
          defaultMessage: 'Send Feedback',
        })}
      >
        <SupportFeedbackIconButton onClick={onOpen}>
          <SupportFeedbackIcon />
        </SupportFeedbackIconButton>
      </Tooltip>
      {open && <SupportFeedbackDialogComponent onClose={onClose} />}
    </Box>
  );
}
