import { ArrowIcon } from 'components/Icons';
import { Theme } from '@mui/material';

export default {
  defaultProps: {
    separator: <ArrowIcon />,
  },
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        ...theme.typography.body2,
        color: theme.palette.grey['700'],
      };
    },
    li({ theme }: { theme: Theme }) {
      return {
        '&:first-of-type': {
          color: theme.palette.common.black,
        },
      };
    },
    separator({ theme }: { theme: Theme }) {
      return {
        color: theme.palette.grey['600'],
        margin: '0 3px',
      };
    },
  },
};
