export const FEEDBACK_STATUS_DRAFT = 'draft';
export const FEEDBACK_STATUS_SUBMITTED = 'submitted';
export const FEEDBACK_STATUS_FINALIZED = 'finalized';

export const FEEDBACK_REQUEST_STATUS_DRAFT = 'draft';
export const FEEDBACK_REQUEST_STATUS_PENDING = 'pending';
export const FEEDBACK_REQUEST_STATUS_ACCEPTED = 'accepted';
export const FEEDBACK_REQUEST_STATUS_DECLINED = 'declined';

export const FEEDBACK_REQUEST_GOAL_STATUS_PENDING = 'pending';
export const FEEDBACK_REQUEST_GOAL_STATUS_ACCEPTED = 'accepted';

export const DEVIATING_SCORE_VALUES = [2, 3, 5];
