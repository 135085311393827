import { Stack, styled, Box } from '@mui/material';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from 'components/Dialog';
import { LogIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import blackListProps from 'styles/helpers/blackListProps';

export const Dialog = styled(MuiDialog)(() => ({
  '.MuiDialog-container > .MuiPaper-root': {
    minWidth: '80%',
    maxWidth: '1600px',
    minHeight: '90vh',
    maxHeight: '90vh',
  },
}));

export const ChangelogItemContainer = styled(Stack)(({ theme }) => ({
  ...theme.typography.body1,
  background: theme.palette.grey['100'],
  marginBottom: theme.spacing(0.5),
  alignItems: 'row',
  padding: '4px 16px',
  justifyContent: 'space-between',
}));

ChangelogItemContainer.defaultProps = {
  direction: 'row',
};

export const StyledPagination = styled(Pagination)(() => ({
  padding: '16px 16px 0',
}));

export const Cell = styled(Box, {
  shouldForwardProp: blackListProps(['width']),
})<{ width: string }>(({ width }) => ({
  whiteSpace: 'pre-wrap',
  lineBreak: 'anywhere',
  minWidth: width,
  maxWidth: width,
  width,
  ul: {
    marginBlockStart: 4,
    marginBlockEnd: 4,
    marginInlineStart: 0,
    marginInlineEnd: 0,
    paddingInlineStart: 16,
  },
}));

export const MarginWrapper = styled(Box)(() => ({
  marginTop: '4px',
  marginBottom: '2px',
}));

export const HeaderContainer = styled(Stack)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey.A500,
  margin: '24px 36px 16px',
  alignItems: 'row',
  justifyContent: 'space-between',
}));

HeaderContainer.defaultProps = {
  direction: 'row',
};

export const HeaderCell = styled(Box, {
  shouldForwardProp: blackListProps(['width']),
})<{ width: string }>(({ width }) => ({
  minWidth: width,
  maxWidth: width,
  width,
}));

export const DialogContent = styled(MuiDialogContent)(() => ({
  padding: '0 20px',
}));

export const DialogActions = styled(MuiDialogActions)(() => ({
  marginTop: '16px',
}));

export const BoldLi = styled('li')(() => ({
  fontWeight: 600,
}));

export const StyledLogIcon = styled(LogIcon)(({ theme }) => ({
  color: theme.palette.blue[500],
  fontSize: '20px',
}));
