import { Component, ReactNode } from 'react';

export default class ChangelogItemErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return null;
    }

    return props.children;
  }
}
