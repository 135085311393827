import {
  Box,
  IconButton as MUIIconButton,
  List,
  ListItem,
  styled,
  Tab as MUITab,
  Tabs as MUITabs,
} from '@mui/material';
import customTheme from 'styles/theme';
import Label from 'components/Label';
import { ScorecardStatus } from 'store/interfaces';
import blackListProps from 'styles/helpers/blackListProps';
import {
  SCORECARD_STATUS_ACTIVE,
  SCORECARD_STATUS_COMPLETED,
  SCORECARD_STATUS_DELETED,
  SCORECARD_STATUS_FEEDBACK_REQUESTED,
  SCORECARD_STATUS_GOALS_APPROVED,
  SCORECARD_STATUS_GOALS_PREPARED,
  SCORECARD_STATUS_READY_FOR_REVIEW,
} from 'constants/scorecards';
import { ScorecardStatusLabelProps } from './interfaces';

const getColorByStatus = (
  theme: typeof customTheme,
  status: ScorecardStatus,
) => {
  switch (status) {
    case SCORECARD_STATUS_ACTIVE:
      return theme.palette.grey.A300;
    case SCORECARD_STATUS_COMPLETED:
      return theme.palette.green['400'];
    case SCORECARD_STATUS_FEEDBACK_REQUESTED:
      return theme.palette.yellow['500'];
    case SCORECARD_STATUS_READY_FOR_REVIEW:
      return theme.palette.blue['200'];
    case SCORECARD_STATUS_GOALS_PREPARED:
      return theme.palette.purple['300'];
    case SCORECARD_STATUS_GOALS_APPROVED:
      return theme.palette.blue['700'];
    case SCORECARD_STATUS_DELETED:
      return theme.palette.red['100'];
    default:
      return theme.palette.orange['500'];
  }
};

export const ScorecardLabel = styled(Label, {
  shouldForwardProp: blackListProps(['status']),
})<ScorecardStatusLabelProps>(({ theme, status }) => ({
  ...theme.typography.subheadingSmall,
  backgroundColor: getColorByStatus(theme, status),
  color: theme.palette.common.black,
}));

export const Tab = styled(MUITab)(({ theme }) => ({
  ...theme.typography.heading5,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `10px 12px`,
  minHeight: 'unset',
  lineHeight: '20px',
  textTransform: 'none',
  minWidth: 'unset',
  borderRadius: '4px',
  margin: 0,
  transition: 'background .2s',
  maxWidth: '300px',

  '&.Mui-selected, &:hover': {
    background: theme.palette.common.white,
  },
  '&>.MuiTab-iconWrapper': {
    marginRight: '4px',
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    float: 'left',
    margin: 0,
  },
  '&.Mui-disabled': {
    color: theme.palette.grey.A500,
    pointerEvents: 'auto',
  },
}));

export const TabName = styled(Box)(() => ({
  marginLeft: '4px',
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const TabInfo = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  marginLeft: '4px',
}));

export const TabsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
}));

export const ScorecardHeader = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  justifyContent: 'space-between',
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  '&:empty': {
    display: 'none',
  },
  '.MuiButton-root': {
    marginLeft: theme.spacing(1),
  },
}));

export const Tabs = styled(MUITabs)(({ theme }) => ({
  background: theme.palette.grey[200],
  padding: '4px',
  borderRadius: '4px',
  minHeight: 'unset',
  marginRight: 'auto',
  '.MuiTab-root+.MuiTab-root': {
    marginLeft: '12px',
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.MuiTabs-scrollableX': {
    order: 1,
  },
  '.MuiTabs-scrollButtons': {
    height: '28px',
    alignSelf: 'center',
    width: '28px',
    border: `1px solid currentColor`,
    borderRadius: '4px',
    padding: 0,
    color: theme.palette.primary.main,
    order: 2,
    marginLeft: theme.spacing(0.5),
    '&.Mui-disabled': {
      opacity: 1,
      color: theme.palette.grey.A700,
      borderColor: theme.palette.grey.A400,
    },
  },
}));

Tabs.defaultProps = {
  variant: 'scrollable',
  scrollButtons: 'auto',
};

export const TopInfoContainer = styled(List)(({ theme }) => ({
  ...theme.typography.body1,
  alignItems: 'center',
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'flex-end',
  'li:not(:first-of-type)': {
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiChip-root, & .MuiBox-root': {
    verticalAlign: 'top',
    lineHeight: 1,
    margin: 0,
  },
}));

export const TopInfoContainerItem = styled(ListItem)(({ theme }) => ({
  whiteSpace: 'nowrap',
  '&.department': {
    ...theme.typography.subheadingSmall,
    minWidth: '96px',
  },
  '&.country': {
    minWidth: '84px',
  },
  '&.spr': {
    minWidth: '72px',
  },
  '&.score': {
    minWidth: '90px',
  },
}));

TopInfoContainerItem.defaultProps = {
  disablePadding: true,
};

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: theme.palette.blue[500],
  marginLeft: '10px',
  width: '40px',
  height: '40px',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
  '&:first-of-type': {
    marginLeft: theme.spacing(1),
  },
}));
