import { ChangeGroup } from 'store/interfaces';

export default function ProjectChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  const firstChange = changeGroups[0];
  const { previousState, dataChangePayload } = firstChange;
  const data = previousState ?? dataChangePayload;
  const project = data?.assessedByRaw ?? '';

  return <div>{project}</div>;
}
