import { styled, Box, Button } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: '100%',
}));

export const TopChildrenHolder = styled('div')({
  marginBottom: '16px',
});

export const RightChildrenHolder = styled('div')({
  marginLeft: 'auto',
});

export const FiltersContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(1),
  display: 'inline-flex',
  gap: theme.spacing(0.5),
  '.MuiAutocomplete-root ': {
    minWidth: '203px',
  },
}));

export const ResetButton = styled(Button)<{ iconOnlyReset?: boolean }>(
  ({ iconOnlyReset }) => ({
    span: {
      marginRight: iconOnlyReset ? 0 : '8px',
      marginLeft: iconOnlyReset ? 0 : '-4px',
    },
  }),
);
