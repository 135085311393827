import FullScreenLayout from 'components/Layout';
import SignIn from 'pages/SignIn/SignIn.component';
import { useSelector } from 'react-redux';
import Header from 'components/Header';
import {
  selectIsLoggedIn,
  selectIsProfileInit,
  selectUserCanUseApp,
} from 'store/profile/selectors';
import Toast from 'components/Toast';
import PageLoader from 'components/PageLoader';
import AppRoutes from 'components/AppRoutes';
import useErrorHandler from 'hooks/useErrorHandler';
import OptimisticLockingDialog from 'components/OptimisticLockingDialog';

function App() {
  const loggedIn = useSelector(selectIsLoggedIn);
  const isInit = useSelector(selectIsProfileInit);
  const canUse = useSelector(selectUserCanUseApp);
  useErrorHandler();
  if (!isInit) {
    return <PageLoader open />;
  }
  return (
    <FullScreenLayout>
      {!loggedIn || !canUse ? (
        <SignIn />
      ) : (
        <>
          <Header />
          <AppRoutes />
        </>
      )}
      <Toast />
      <OptimisticLockingDialog />
    </FullScreenLayout>
  );
}

export default App;
