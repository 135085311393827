import { FormattedMessage } from 'react-intl';
import { CloseIcon } from 'components/Icons';
import { commonTexts } from 'i18n';
import { Tooltip } from '@mui/material';
import { FilterBarProps } from './interfaces';
import {
  Container,
  TopChildrenHolder,
  FiltersContainer,
  RightChildrenHolder,
  ResetButton,
} from './FilterBar.styled';

function FilterBar({
  children,
  onClearFilters,
  showClearButton,
  topChildren,
  rightChildren,
  disableClearButton,
  sx,
  filtersSx,
  iconOnlyReset,
}: FilterBarProps) {
  return (
    <Container sx={sx}>
      {topChildren ? (
        <TopChildrenHolder>{topChildren}</TopChildrenHolder>
      ) : null}
      <FiltersContainer sx={filtersSx}>
        {children}
        {showClearButton && (
          <Tooltip
            title={
              iconOnlyReset ? (
                <FormattedMessage {...commonTexts.resetAllFilters} />
              ) : null
            }
          >
            <ResetButton
              onClick={onClearFilters}
              disabled={disableClearButton}
              startIcon={<CloseIcon />}
              iconOnlyReset={iconOnlyReset}
            >
              {iconOnlyReset ? null : (
                <FormattedMessage {...commonTexts.resetAllFilters} />
              )}
            </ResetButton>
          </Tooltip>
        )}
        {rightChildren ? (
          <RightChildrenHolder>{rightChildren}</RightChildrenHolder>
        ) : null}
      </FiltersContainer>
    </Container>
  );
}

export default FilterBar;
