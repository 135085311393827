import { RefObject } from 'react';
import { Autocomplete as MUIAutocomplete } from '@mui/material';
import classNames from 'classnames';
import { UIOption } from 'store/options';
import useAutocomplete from 'hooks/useAutocomplete';
import TextField from '../TextField';
import { List } from './Autocomplete.styled';
import { AutocompleteProps } from './interfaces';

function Autocomplete<
  T extends UIOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  size = 'medium',
  placeholder,
  label,
  isOptionEqualToValue,
  ListboxProps = {},
  helperText,
  error,
  required,
  inputRef,
  multiple,
  renderOption: inputRenderOption,
  clearValue,
  groupBy,
  isInModal,
  dataTestId,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const {
    sortedOptions,
    defaultIsOptionEqualToValue,
    renderTags,
    renderGroup,
    renderOption,
  } = useAutocomplete<T, Multiple, DisableClearable, FreeSolo>({
    options,
    multiple,
    clearValue,
    renderOption: inputRenderOption,
    groupBy,
  });
  const hasAll = options[0]?.id === 'all';
  const displayLabel = hasAll && !label && placeholder ? placeholder : label;
  if (hasAll && props.value === null) {
    Object.assign(props, { value: options[0] });
  }
  return (
    <MUIAutocomplete
      blurOnSelect={!multiple}
      options={sortedOptions}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
      ListboxProps={{
        ...ListboxProps,
        className: classNames(ListboxProps.className, `SelectMenuSize-${size}`),
      }}
      ListboxComponent={List}
      multiple={multiple}
      groupBy={groupBy}
      disableCloseOnSelect={multiple}
      renderTags={renderTags}
      renderOption={renderOption}
      renderGroup={renderGroup}
      data-testid={dataTestId}
      {...props}
      renderInput={(textFieldProps) => (
        <TextField
          error={error}
          helperText={helperText}
          label={displayLabel}
          placeholder={placeholder}
          required={required}
          ref={inputRef as RefObject<HTMLInputElement>}
          {...textFieldProps}
          InputLabelProps={{
            ...(textFieldProps.InputLabelProps ?? {}),
            shrink: true,
          }}
          size={size}
        />
      )}
      componentsProps={{
        paper: {
          sx: {
            minWidth: 'fit-content',
          },
        },
        popper: {
          sx: {
            zIndex: isInModal ? 10000 : 999,
          },
        },
      }}
    />
  );
}

Autocomplete.defaultProps = {
  placeholder: '',
  disablePortal: false,
};

export default Autocomplete;
