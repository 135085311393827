import { ListItemText, MenuItem } from '@mui/material';
import { InfoIcon } from 'components/Icons';
import { DrowpdownCheckbox } from 'components/MultiselectAutocomplete/MultiselectAutocomplete.styled';
import Tooltip from 'components/Tooltip';
import { HTMLAttributes } from 'react';
import { Option } from 'store/options';

export default function RoleOptionWithInfo({
  optionProps,
  option,
  selected,
  hideCheckbox,
}: {
  optionProps: HTMLAttributes<HTMLLIElement>;
  option: Option & { description?: string };
  selected: boolean;
  hideCheckbox?: boolean;
}) {
  const { disabled, id, name, description } = option;
  return (
    <MenuItem disabled={disabled} {...optionProps} key={id || name}>
      {hideCheckbox ? null : (
        <DrowpdownCheckbox
          size="medium"
          disabled={disabled}
          checked={selected}
        />
      )}
      <ListItemText primary={name} />
      <Tooltip
        onClick={(e) => {
          // prevent click on tooltip from triggering the click on the menu item
          e.stopPropagation();
        }}
        sx={{ pointerEvents: 'all' }}
        title={description}
      >
        {description ? (
          <InfoIcon color="info" sx={{ width: 18, height: 18 }} />
        ) : null}
      </Tooltip>
    </MenuItem>
  );
}
