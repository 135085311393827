import { Box, IconButton, Popover } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { useMenuAnchor, useFetch } from 'hooks';
import { useIntl } from 'react-intl';
import { VERSION, BRANCH, COMMIT } from 'constants/appInfo';
import { API_VERSION } from 'constants/api';
import {
  Icon,
  VersionsWrapper,
  VersionInfo,
  Progress,
} from './VersionsInfo.styled';

export default function VersionsInfo() {
  const { formatMessage } = useIntl();
  const { open, anchorEl, openMenu, closeMenu } = useMenuAnchor();
  const { data, status } = useFetch<{ api_version: string }>(API_VERSION);

  return (
    <Box mr={0.25}>
      <Tooltip
        title={formatMessage({
          id: 'common.versions',
          defaultMessage: 'Versions',
        })}
      >
        <IconButton onClick={openMenu}>
          <Icon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        transformOrigin={{
          vertical: -50,
          horizontal: 60,
        }}
      >
        <VersionsWrapper>
          <VersionInfo>
            UI version: {VERSION} ({BRANCH} {COMMIT})
          </VersionInfo>
          <VersionInfo>
            API version:{' '}
            {status === 'loading' ? <Progress size={14} /> : data?.api_version}
          </VersionInfo>
        </VersionsWrapper>
      </Popover>
    </Box>
  );
}
