import { Table, TableHead, TableBody } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import EmptyState from 'components/EmptyState';
import { commonTexts } from 'i18n';
import { CustomTableProps } from './interfaces';
import {
  TableContainer,
  TableBodyCell,
  TableHeadCell,
  TableRow,
} from './CustomTable.styled';

export default function CustomTable({ columns, data }: CustomTableProps) {
  return (
    <TableContainer>
      {data.length ? (
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableHeadCell width={column.width} key={column.id}>
                  <FormattedMessage {...column} />
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={(row?.id as number) || index}>
                {columns.map((column) => (
                  <TableBodyCell
                    key={`${column.id}-${(row?.id as number) || index}`}
                  >
                    {column.renderCell(row) || (
                      <FormattedMessage {...commonTexts.dashDefaultValue} />
                    )}
                  </TableBodyCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyState
          title={<FormattedMessage {...commonTexts.noResultWithFilters} />}
        />
      )}
    </TableContainer>
  );
}
