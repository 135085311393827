import { Paper, styled } from '@mui/material';

export const NodeWrapperStyled = styled(Paper)(({ theme }) => ({
  width: '350px',
  border: `1px solid ${theme.palette.grey[500]}`,
  outline: 'none',
  borderRadius: '12px',

  '&:hover': {
    borderColor: theme.palette.grey[800],
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
  '&:active': {
    borderColor: theme.palette.primary.main,
  },
}));
