import { ChangeGroup } from 'store/interfaces';
import { FormattedMessage } from 'react-intl';
import ScorecardStatusLabel from '../ScorecardDetails/ScorecardStatusLabel.component';

export default function StatusUpdateChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  const firstChangeGroup: ChangeGroup = changeGroups[0];
  const previousStatus = firstChangeGroup.previousState?.status;
  const currentStatus = firstChangeGroup.dataChangePayload?.status;

  if (!previousStatus) {
    return (
      <FormattedMessage
        id="pages.scorecards.changedStatusTo"
        defaultMessage="Changed status to <status></status>"
        values={{
          status: () => <ScorecardStatusLabel status={currentStatus} />,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="pages.scorecards.changedStatusFromTo"
      defaultMessage="Changed status from <old></old> to <new></new>"
      values={{
        old: () => <ScorecardStatusLabel status={previousStatus} />,
        new: () => <ScorecardStatusLabel status={currentStatus} />,
      }}
    />
  );
}
