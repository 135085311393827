import {
  AutocompleteRenderOptionState,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { EditIcon } from 'components/Icons';
import MultiselectAutocomplete from 'components/MultiselectAutocomplete';
import { commonTexts, userManagementTexts } from 'i18n';
import {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Option, UserMgmtItem } from 'store/interfaces';
import {
  UserMgmtRole,
  UserMgmtRoleAlias,
  selectIsRolesUpdating,
  selectRoles,
  updateUserRoles,
} from 'store/userMgmt';
import { isEqual } from 'lodash-es';
import { selectOptions } from 'store/options';
import RoleOptionWithInfo from '../common/RoleOptionWithInfo';
import {
  DepartmentFields,
  DepartmentsLabel,
} from './UserManagementTable.styled';

export default function RolesCell({ row }: { row: UserMgmtItem }) {
  const dispatch = useAppDispatch();
  const isUpdating = useSelector(selectIsRolesUpdating);

  const [selectedDepartments, setSelectedDepartments] = useState<
    Option[] | null
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<Option[] | null>(
    [],
  );
  const { locations, units } = useSelector(selectOptions);
  const [isViewMode, setIsViewMode] = useState(true);
  const [selectedRoles, setSelectedRoles] = useState<UserMgmtRole[] | null>(
    row.roles,
  );
  const rolesList = useSelector(selectRoles);
  const rolesListWithDisabledOption = useMemo(
    () =>
      rolesList.map((role) => ({
        ...role,
        // disable service roles
        disabled: !role.isEditable,
      })),
    [rolesList],
  );
  const departmentRole = useMemo(
    () =>
      selectedRoles?.find(
        ({ alias }) => alias === UserMgmtRoleAlias.departmentManager,
      ),
    [selectedRoles],
  );
  const initialDepartments = useMemo(() => {
    const departmens =
      row.roles.find(
        ({ alias }) => alias === UserMgmtRoleAlias.departmentManager,
      )?.params?.['department-scoping'] || [];

    return departmens.map((id) => ({
      name: units.find((department) => department.id === id)?.name || '',
      id,
    }));
  }, [units, row.roles]);
  const initialCountries = useMemo(() => {
    const countries =
      row.roles.find(
        ({ alias }) => alias === UserMgmtRoleAlias.departmentManager,
      )?.params?.['country-scoping'] || [];

    return countries.map((id) => ({
      name: locations.find((country) => country.id === id)?.name || '',
      id,
    }));
  }, [locations, row.roles]);
  const serviceRoleids = useMemo(
    () =>
      rolesList?.filter(({ isEditable }) => !isEditable).map(({ id }) => id) ||
      [],
    [rolesList],
  );

  const onDepartmentsChange = useCallback(
    (e: SyntheticEvent, options: Option[] | null) => {
      setSelectedDepartments(options);
    },
    [],
  );
  const onCountriesChange = useCallback(
    (e: SyntheticEvent, options: Option[] | null) => {
      setSelectedCountries(options);
    },
    [],
  );
  const onRolesChange = useCallback(
    (e: SyntheticEvent, options: UserMgmtRole[] | null) => {
      setSelectedRoles(options);
    },
    [],
  );
  const renderRoleOption = useCallback(
    (
      optionProps: HTMLAttributes<HTMLLIElement>,
      option: Option,
      { selected }: AutocompleteRenderOptionState,
    ) => (
      <RoleOptionWithInfo
        selected={selected}
        option={option}
        optionProps={optionProps}
      />
    ),
    [],
  );
  const removeSelectedRole = useCallback(
    (itemId: number) => {
      setSelectedRoles((prev) => prev?.filter(({ id }) => id !== itemId) || []);
    },
    [setSelectedRoles],
  );
  const removeSelectedDepartment = useCallback(
    (itemId: number) => {
      setSelectedDepartments(
        (prev) => prev?.filter(({ id }) => id !== itemId) || [],
      );
    },
    [setSelectedDepartments],
  );
  const removeSelectedCountry = useCallback(
    (itemId: number) => {
      setSelectedCountries(
        (prev) => prev?.filter(({ id }) => id !== itemId) || [],
      );
    },
    [setSelectedCountries],
  );

  const onSave = useCallback(async () => {
    try {
      await dispatch(
        updateUserRoles({
          id: row.id as number,
          roles:
            selectedRoles
              ?.filter(({ id }) => !serviceRoleids.includes(id))
              ?.map(({ alias, id }) => ({
                id,
                params:
                  alias === UserMgmtRoleAlias.departmentManager
                    ? {
                        'department-scoping':
                          selectedDepartments?.map(
                            (department) => department.id,
                          ) || [],
                        'country-scoping':
                          selectedCountries?.map((country) => country.id) || [],
                      }
                    : undefined,
              })) || [],
        }),
      );
      setIsViewMode(true);
    } catch (e) {
      // e
    }
  }, [
    dispatch,
    row.id,
    selectedCountries,
    selectedDepartments,
    selectedRoles,
    serviceRoleids,
  ]);

  const onCancel = useCallback(() => {
    setSelectedRoles(row.roles);
    setIsViewMode(true);
  }, [row]);

  useEffect(() => {
    setSelectedRoles(row.roles);
  }, [row]);
  useEffect(() => {
    setSelectedDepartments(initialDepartments);
    setSelectedCountries(initialCountries);
  }, [initialCountries, initialDepartments]);

  return (
    <Box>
      {isViewMode ? (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Box>{row.roles.map((role) => role.name).join(', ')}</Box>
            {departmentRole && (
              <Box sx={{ marginTop: '6px' }}>
                <DepartmentsLabel>
                  <FormattedMessage
                    {...userManagementTexts.availableDepartments}
                  />
                </DepartmentsLabel>
                {initialDepartments
                  .map((department) => department.name)
                  .join(', ') || (
                  <FormattedMessage {...commonTexts.allStarDepartments} />
                )}
              </Box>
            )}
            {departmentRole && (
              <Box sx={{ marginTop: '6px' }}>
                <DepartmentsLabel>
                  <FormattedMessage
                    {...userManagementTexts.availableCountries}
                  />
                </DepartmentsLabel>
                {initialCountries.map((country) => country.name).join(', ') || (
                  <FormattedMessage {...commonTexts.allStarLocations} />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <IconButton
              onClick={() => setIsViewMode(false)}
              sx={{ width: 24, height: 24 }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Box>
        </Stack>
      ) : (
        <Stack direction="row">
          <DepartmentFields width="100%">
            <MultiselectAutocomplete
              size="medium"
              selectedOptions={selectedRoles?.map(({ id }) => id) || []}
              options={rolesListWithDisabledOption}
              value={selectedRoles || []}
              removeSelection={removeSelectedRole}
              onChange={onRolesChange}
              renderOption={renderRoleOption}
              showSelectedOptionsNames
              optionMaxWidth={130}
              disableClearButton
              disablePortal={false}
            />
            {selectedRoles?.find(
              ({ alias }) => alias === UserMgmtRoleAlias.departmentManager,
            ) && (
              <>
                <Stack
                  sx={{ marginTop: '8px' }}
                  direction="row"
                  alignItems="center"
                >
                  <Typography
                    width="180px"
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginRight: '8px' }}
                  >
                    <FormattedMessage
                      {...userManagementTexts.availableDepartments}
                    />
                  </Typography>
                  <Box width="100%">
                    <MultiselectAutocomplete
                      size="medium"
                      selectedOptions={
                        selectedDepartments?.map(({ id }) => id) || []
                      }
                      options={units}
                      value={selectedDepartments || []}
                      removeSelection={removeSelectedDepartment}
                      onChange={onDepartmentsChange}
                      showSelectedOptionsNames
                      optionMaxWidth={80}
                      placeholder={commonTexts.all.defaultMessage}
                      className="valuePlaceholder"
                      disablePortal={false}
                    />
                  </Box>
                </Stack>
                <Stack
                  sx={{ marginTop: '8px' }}
                  direction="row"
                  alignItems="center"
                >
                  <Typography
                    width="180px"
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginRight: '8px' }}
                  >
                    <FormattedMessage
                      {...userManagementTexts.availableCountries}
                    />
                  </Typography>
                  <Box width="100%">
                    <MultiselectAutocomplete
                      size="medium"
                      selectedOptions={
                        selectedCountries?.map(({ id }) => id) || []
                      }
                      options={locations}
                      value={selectedCountries || []}
                      removeSelection={removeSelectedCountry}
                      onChange={onCountriesChange}
                      showSelectedOptionsNames
                      optionMaxWidth={100}
                      placeholder={commonTexts.all.defaultMessage}
                      disablePortal={false}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </DepartmentFields>
          <Box sx={{ width: 185, marginLeft: '16px' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              disabled={isUpdating}
              size="small"
            >
              <FormattedMessage {...commonTexts.cancel} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              sx={{ marginLeft: '8px' }}
              disabled={
                isUpdating ||
                (isEqual(selectedRoles, row.roles) &&
                  isEqual(selectedDepartments, initialDepartments) &&
                  isEqual(selectedCountries, initialCountries))
              }
              size="small"
            >
              <FormattedMessage {...commonTexts.save} />
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  );
}
