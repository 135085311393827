import { Position } from 'reactflow';
import { SkillNode, RootNode, NodeGroup } from 'components/Nodes';

export const PAGE_HEIGHT_OFFSET = '190px';

export enum RoadmapEvents {
  EXPORT = 'export',
  ADD_NODE = 'add_node',
  DELETE_NODE = 'delete_node',
  EDIT_NODE = 'edit_node',
  COPY_NODE = 'copy_node',
  SAVE = 'save',
}

export enum NodeTypesEnum {
  ROOT = 'root',
  GROUP = 'group',
  SKILL = 'skill',
}

export const NODE_ELEMENTS = {
  [NodeTypesEnum.GROUP]: NodeGroup,
  [NodeTypesEnum.ROOT]: RootNode,
  [NodeTypesEnum.SKILL]: SkillNode,
};

export const EDGES_POSITIONS_LIST = [
  Position.Top,
  Position.Bottom,
  Position.Right,
  Position.Left,
];

export const EDGES_TYPES_LIST: ('targetPosition' | 'sourcePosition')[] = [
  'targetPosition',
  'sourcePosition',
];

export enum FilterTypeEnum {
  SENIORITY = 'seniority',
  TAG = 'tag',
}

export enum SeniorityType {
  Trainee = 1,
  Junior,
  Middle,
  Senior,
  Manager,
}
