import PageLoader from 'components/PageLoader';
import { Suspense } from 'react';
import { AppRouteComponentProps } from 'components/AppRoutes';
import UserManagement from './UserManagement.component';

export default function UserManagementPage(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <UserManagement pageProps={props} />
    </Suspense>
  );
}
