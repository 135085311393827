import { createIntl, createIntlCache } from 'react-intl';
import enTranslations from './compiled-translations/en.json';

const cache = createIntlCache();

export const defaultLocale = 'en';
export const locale = 'en';

const intl = createIntl(
  {
    locale,
    defaultLocale,
    messages: enTranslations,
  },
  cache,
);

export { default as commonTexts } from './common';
export { default as formTexts } from './form';
export { default as reviewCyclesTexts } from './reviewCycles';
export { default as scorecardsTexts } from './scorecards';
export { default as myTeamTexts } from './myTeam';
export { default as feedbackRequestsTexts } from './feedbackRequests';
export { default as departmentOverviewTexts } from './departmentOverview';
export { default as errorTexts } from './error';
export { default as careerRoadmapTexts } from './careerRoadmap';
export { default as userManagementTexts } from './userManagement';

export default intl;
