import { Box, Stack, styled } from '@mui/system';
import typography from 'styles/theme/typography';

export const UserManagementTableWrapper = styled(Box)`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey.A400};
`;

export const UserPosition = styled(Box)(({ theme }) => ({
  color: theme.palette.grey.A500,
  ...typography.body3,
}));

export const DepartmentsLabel = styled('span')(({ theme }) => ({
  color: theme.palette.grey[800],
  marginRight: theme.spacing(0.5),
}));

export const DepartmentFields = styled(Box)(({ theme }) => ({
  '.MuiOutlinedInput-input, .MuiAutocomplete-input.MuiAutocomplete-input': {
    '::placeholder': {
      color: theme.palette.common.black,
      opacity: 1,
    },
    ':focus::placeholder': {
      opacity: 0,
    },
  },
}));

export const ChangeLogWrapper = styled(Stack)(() => ({
  paddingLeft: '3px',
  paddingBottom: '8px',
}));
