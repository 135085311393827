import { CSSProperties } from 'react';

const baseFontFamily = "'Proxima Nova', sans-serif";

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: CSSProperties;
    h8: CSSProperties;
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
    body6: CSSProperties;
    subtitle3: CSSProperties;
    heading1: CSSProperties;
    'heading1.2': CSSProperties;
    heading2: CSSProperties;
    heading3: CSSProperties;
    heading4: CSSProperties;
    heading5: CSSProperties;
    heading7: CSSProperties;
    subheadingSmall: CSSProperties;
    subheadingMedium: CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: CSSProperties;
    h8?: CSSProperties;
    body3?: CSSProperties;
    body4?: CSSProperties;
    body5?: CSSProperties;
    body6?: CSSProperties;
    subtitle3?: CSSProperties;
    heading1?: CSSProperties;
    'heading1.2'?: CSSProperties;
    heading2?: CSSProperties;
    heading3?: CSSProperties;
    heading4?: CSSProperties;
    heading5?: CSSProperties;
    heading7?: CSSProperties;
    subheadingSmall?: CSSProperties;
    subheadingMedium?: CSSProperties;
  }
}

export default {
  fontFamily: baseFontFamily,
  h1: {
    // Heading 1
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: 1,
  },
  h2: {
    // Heading 2
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.3,
  },
  h3: {
    // Heading 3
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 32,
    lineHeight: 1.3,
  },
  h4: {
    // Heading 4
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 28,
    lineHeight: 1.2857,
  },
  h5: {
    // Heading 5
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1.2727,
  },
  h6: {
    // Heading 6
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.33333,
  },
  h7: {
    // Heading 7
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.375,
  },
  h8: {
    // Heading 8
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.4286,
  },
  subtitle1: {
    // Subheading Big
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.375,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
  },
  subtitle2: {
    // Subheading Medium
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.4286,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
  },
  subtitle3: {
    // Subheading Small
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
  },
  body1: {
    // Body 1
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  body2: {
    // Body 2
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
  },
  body3: {
    // Body 3
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
  },
  body4: {
    // Body 4
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4286,
  },
  body5: {
    // Body 5
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.3846,
  },
  body6: {
    // Body 6
    fontFamily: baseFontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.4167,
  },
  heading1: {
    // Heading 1
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '50px',
  },
  'heading1.2': {
    // Heading 1.2
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 38,
    lineHeight: '38px',
  },
  heading2: {
    // Heading 2
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
  },
  heading3: {
    // Heading 3
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '28px',
  },
  heading4: {
    // Heading 4
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
  },
  heading5: {
    // Heading 5
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
  },
  heading7: {
    // Heading 7
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px',
  },
  subheadingSmall: {
    // Subheading Small
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.002em',
    textTransform: 'uppercase',
  },
  subheadingMedium: {
    // Subheading Medium
    fontFamily: baseFontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.002em',
    textTransform: 'uppercase',
  },
};
