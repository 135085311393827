import {
  ChangeGroup,
  GoalChange,
  GoalChangeDetails,
  ParamsChange,
  SelfAssessmentEnum,
  SubgoalChange,
  SubgoalChangeDetails,
} from 'store/interfaces';
import { mergeScorecardUpdateChanges } from 'helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { formTexts, commonTexts, scorecardsTexts } from 'i18n';
import { SCORECARD_COMMON_GOAL_TYPE_CUSAT } from 'constants/scorecards';
import SelfAssessmentSetupChangelogItem from './SelfAssessmentSetupChangelog.component';

const selfAssessmentParams: string[] = [
  SelfAssessmentEnum.INCLUDE,
  SelfAssessmentEnum.ENABLE,
];

function isNullOrEmptyString(value: any) {
  return value == null || value === '';
}

function UpdateDescription({
  payload,
  previousState,
}: {
  payload: GoalChangeDetails & SubgoalChangeDetails;
  previousState: GoalChangeDetails & SubgoalChangeDetails;
}) {
  const { formatMessage } = useIntl();
  if (!payload || !previousState) return null;
  const { name, weight, description, assessmentScaleGroup, extraMultiplier } =
    payload;
  const {
    name: prevName,
    weight: prevWeight,
    description: prevDecription,
    assessmentScaleGroup: prevAssessmentScaleGroup,
    extraMultiplier: prevExtraMultiplier,
  } = previousState;

  const fields = [
    {
      field: formatMessage(formTexts.name),
      prev: prevName,
      curr: name,
      fieldName: 'name',
    },
    {
      field: formatMessage(commonTexts.weight),
      prev: `${prevWeight}%`,
      curr: `${weight}%`,
      fieldName: 'weight',
    },
    {
      field: formatMessage(commonTexts.description),
      prev: isNullOrEmptyString(prevDecription) ? '""' : prevDecription,
      curr: isNullOrEmptyString(description) ? '""' : description,
      fieldName: 'description',
    },
    {
      field: formatMessage(formTexts.assessmentScale),
      prev: prevAssessmentScaleGroup?.name ?? '""',
      curr: assessmentScaleGroup?.name,
      fieldName: 'assessmentScaleGroup',
    },
    {
      field: formatMessage(scorecardsTexts.multiplier),
      prev: prevExtraMultiplier?.name,
      curr: extraMultiplier?.name ?? '""',
      fieldName: 'extraMultiplier',
    },
  ];
  return (
    <ul>
      {fields.map(({ field, prev, curr, fieldName }) => {
        if (Object.hasOwn(payload, fieldName)) {
          return (
            <li key={field}>
              <FormattedMessage
                {...scorecardsTexts.updatedField}
                values={{
                  field,
                  prev,
                  curr,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
}

function CreateDescription({
  payload,
  isSubgoal,
}: {
  payload: GoalChangeDetails & SubgoalChangeDetails;
  isSubgoal?: boolean;
}) {
  if (!payload) return null;
  const { name, weight, description, assessmentScaleGroup } = payload;
  const fields = [
    {
      fieldName: 'name',
      value: name,
      field: formTexts.name,
      formatted: true,
    },
    {
      fieldName: 'weight',
      value: `${weight}%`,
      field: commonTexts.weight,
      isHidden: isSubgoal && payload.type === SCORECARD_COMMON_GOAL_TYPE_CUSAT,
    },
    {
      fieldName: 'description',
      value: isNullOrEmptyString(description) ? '""' : description,
      field: formTexts.description,
      formatted: true,
      isHidden: !isSubgoal && payload.type === SCORECARD_COMMON_GOAL_TYPE_CUSAT,
    },
    {
      fieldName: 'assessmentScaleGroup',
      value: assessmentScaleGroup?.name,
      field: formTexts.assessmentScale,
      isHidden: isSubgoal && payload.type === SCORECARD_COMMON_GOAL_TYPE_CUSAT,
    },
  ];

  return (
    <ul>
      {fields.map(({ field, value, fieldName, formatted, isHidden }) => {
        if (Object.hasOwn(payload, fieldName) && !isHidden) {
          return (
            <li key={field.id}>
              <FormattedMessage {...field} />:{' '}
              {formatted ? <i>{value}</i> : value}
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
}

CreateDescription.defaultProps = {
  isSubgoal: false,
};

function SubgoalChangeType({
  type,
  name,
  previousState,
}: {
  type: string;
  name: string;
  previousState: { name?: string };
}) {
  let text = scorecardsTexts.updatedSubgoalDescription;
  const isDelete = type === 'delete';

  if (isDelete) {
    text = scorecardsTexts.deletedSubgoalDescription;
  }

  if (type === 'create') {
    text = scorecardsTexts.createdSubgoalDescription;
  }

  return (
    <FormattedMessage
      {...text}
      values={{
        name: isDelete ? previousState.name : name,
        br: <br />,
        i: (chunks) => <i>{chunks}</i>,
      }}
    />
  );
}

function SubgoalChangelog({
  id,
  name,
  type,
  payload,
  previousState,
}: SubgoalChange) {
  return (
    <li key={id}>
      <SubgoalChangeType
        name={name}
        type={type}
        previousState={previousState}
      />
      {type === 'update' && (
        <UpdateDescription payload={payload} previousState={previousState} />
      )}
      {type === 'create' && <CreateDescription isSubgoal payload={payload} />}
    </li>
  );
}

function GoalChangeType({
  type,
  name,
  previousState,
}: {
  type: string;
  name: string;
  previousState: { name?: string };
}) {
  let text = scorecardsTexts.updatedGoalDescription;
  const isDelete = type === 'delete';

  if (isDelete) {
    text = scorecardsTexts.deletedGoalDescription;
  }

  if (type === 'create') {
    text = scorecardsTexts.createdGoalDescription;
  }

  return (
    <FormattedMessage
      {...text}
      values={{
        name: isDelete ? previousState?.name : name,
        br: <br />,
        i: (chunks) => <i>{chunks}</i>,
      }}
    />
  );
}

function getParamName(paramName: string) {
  switch (paramName) {
    case 'Allow goal edit':
      return <FormattedMessage {...formTexts.allowScorecardOwnerEdits} />;
    case 'Ask for goal feedback':
      return <FormattedMessage {...formTexts.allowComment} />;
    default:
      return paramName;
  }
}

export function ParamsChangelog({ payload: { params } }: ParamsChange) {
  const { formatMessage } = useIntl();
  if (Array.isArray(params)) {
    const enabledText = formatMessage(commonTexts.enabled);
    const disabledText = formatMessage(commonTexts.disabled);

    return (
      <ul>
        {params.map((param) =>
          selfAssessmentParams.includes(param.name) ? (
            <SelfAssessmentSetupChangelogItem
              params={params}
              changedParam={param}
            />
          ) : (
            <li key={param.id}>
              <FormattedMessage
                {...scorecardsTexts.updatedParamsField}
                values={{
                  field: getParamName(param.name),
                  curr: param.payload.value ? enabledText : disabledText,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
          ),
        )}
      </ul>
    );
  }

  return null;
}

function GoalChangelog({ name, type, payload, previousState }: GoalChange) {
  return (
    <>
      <GoalChangeType type={type} name={name} previousState={previousState} />
      {type === 'update' && (
        <UpdateDescription payload={payload} previousState={previousState} />
      )}
      {type === 'create' && <CreateDescription payload={payload} />}
      {payload.subgoals?.map((subgoal) => (
        <ul key={subgoal.id}>
          <SubgoalChangelog {...subgoal} />
        </ul>
      ))}
      {payload.params && (
        <ParamsChangelog payload={payload} previousState={previousState} />
      )}
    </>
  );
}

export default function ScorecardUpdateChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  const goalChanges: GoalChange[] = mergeScorecardUpdateChanges(changeGroups);
  return (
    <div>
      {goalChanges.map((goalChange) => (
        <GoalChangelog key={goalChange.id} {...goalChange} />
      ))}
    </div>
  );
}
