import CustomTable from 'components/CustomTable';
import { userManagementTexts } from 'i18n';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import {
  UserMgmtItem,
  getUsers,
  selectLastChange,
  selectUserMgmtLoading,
  selectUserMgmtPagination,
  selectUserMgmtUsers,
} from 'store/userMgmt';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'components/ContentLoader';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store';
import ChangelogDialog, {
  LastUpdateInfo,
  ShowChangelogButton,
} from 'components/ChangelogDialog';
import { useDialogState } from 'hooks';
import RolesCell from './RolesCell.component';
import {
  ChangeLogWrapper,
  UserManagementTableWrapper,
  UserPosition,
} from './UserManagementTable.styled';

const columns = [
  {
    ...userManagementTexts.name,
    width: 360,
    renderCell: (row: UserMgmtItem) => {
      const wr = row.workRecords?.find(({ status }) => status === 'Active');

      return (
        <Box>
          <Box>{row.fullName}</Box>
          <UserPosition>
            {wr?.position?.name}
            {wr?.seniority ? `, ${wr?.seniority}` : ''}
          </UserPosition>
        </Box>
      );
    },
  },
  {
    ...userManagementTexts.rolesAssigned,
    renderCell: (row: UserMgmtItem) => <RolesCell row={row} />,
  },
];

export default function UserManagementTable() {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUserMgmtUsers);
  const lastChange = useSelector(selectLastChange);
  const pagination = useSelector(selectUserMgmtPagination);
  const loading = useSelector(selectUserMgmtLoading);
  const [isChangelogDialogShown, showChangelogDialog, hideChangelogDialog] =
    useDialogState();
  const hasMore = useMemo(
    () => !loading && pagination?.total > users.length,
    [loading, pagination?.total, users.length],
  );
  const loadMore = useCallback(() => {
    dispatch(getUsers({ loadMore: true }));
  }, [dispatch]);

  return (
    <>
      <ChangeLogWrapper direction="row" alignItems="center">
        <LastUpdateInfo type="userManagement" {...lastChange} />
        <ShowChangelogButton
          type="userManagement"
          showChangelogDialog={showChangelogDialog}
        />
      </ChangeLogWrapper>
      <UserManagementTableWrapper>
        <InfiniteScroll
          next={loadMore}
          hasMore={hasMore}
          loader={<ContentLoader />}
          dataLength={users.length}
        >
          <CustomTable data={users} columns={columns} />
        </InfiniteScroll>
        {isChangelogDialogShown && (
          <ChangelogDialog
            onClose={hideChangelogDialog}
            type="userManagement"
          />
        )}
      </UserManagementTableWrapper>
    </>
  );
}
