import { DialogTitle, LoaderHolder } from 'components/Dialog';
import { Box, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { commonTexts } from 'i18n';
import { ChangeEvent, useCallback } from 'react';
import { useSCChangelog } from 'hooks/scorecards';
import { useUMChangelog } from 'hooks/userManagement';
import ContentLoader from 'components/ContentLoader';
import {
  Dialog,
  DialogContent,
  DialogActions,
  HeaderCell,
  HeaderContainer,
  StyledPagination,
} from './ChangelogDialog.styled';
import { ChangelogDialogProps } from '../ScorecardDetails/interfaces';
import ChangelogItem from './ChangelogItem.component';
import LastUpdateInfo from './LastUpdatedInfo.component';

const changelogsByType = {
  scorecards: useSCChangelog,
  userManagement: useUMChangelog,
};

export default function ChangelogDialog({
  onClose,
  type,
  params,
}: ChangelogDialogProps) {
  const { data, fetchPage, loading } = changelogsByType[type](params);
  const { items, pagination } = data;
  const { limit, page, total } = pagination;
  const count = Math.ceil(total / limit) || 1;

  const onPaginationChange = useCallback(
    (e: ChangeEvent<unknown>, newPage: number) => {
      if (page !== newPage) {
        fetchPage(newPage);
      }
    },
    [fetchPage, page],
  );

  const firstItem = items[0];

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <FormattedMessage
          id="pages.scorecards.Changelog"
          defaultMessage="Change Log"
        />
      </DialogTitle>
      {items?.length > 1 && (
        <Box m="20px 24px 0px">
          <LastUpdateInfo {...firstItem} />
        </Box>
      )}
      <HeaderContainer>
        <HeaderCell width="14%">
          <FormattedMessage id="pages.scorecards.date" defaultMessage="Date" />
        </HeaderCell>
        <HeaderCell width="14%">
          <FormattedMessage
            id="pages.scorecards.Author"
            defaultMessage="Author"
          />
        </HeaderCell>
        <HeaderCell width="19%">
          <FormattedMessage
            id="pages.scorecards.change"
            defaultMessage="Change"
          />
        </HeaderCell>
        <HeaderCell width="48%">
          <FormattedMessage
            id="pages.scorecards.details"
            defaultMessage="Details"
          />
        </HeaderCell>
      </HeaderContainer>
      <DialogContent>
        {items?.map((item) => (
          <ChangelogItem key={item.doneAt} {...item} />
        ))}
      </DialogContent>
      {count > 1 && (
        <StyledPagination
          count={count}
          page={page}
          onChange={onPaginationChange}
        />
      )}
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          <FormattedMessage {...commonTexts.close} />
        </Button>
      </DialogActions>
      {loading && (
        <LoaderHolder>
          <ContentLoader />
        </LoaderHolder>
      )}
    </Dialog>
  );
}
