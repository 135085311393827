import {
  styled,
  TableCell,
  TableContainer as MUITableContainer,
  TableRow as MUITableRow,
} from '@mui/material';
import typography from 'styles/theme/typography';

export const TableContainer = styled(MUITableContainer)({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
});

export const TableHeadCell = styled(TableCell)(({ theme }) => ({
  padding: `9px 16px`,
  border: 'none',
  color: theme.palette.grey.A500,
  background: theme.palette.common.white,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '.MuiBox-root': {
    whiteSpace: 'nowrap',
  },
  '&.MuiTableCell-head': {
    ...typography.body2,
  },
}));

export const TableBodyCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  background: 'transparent',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  overflowX: 'hidden',
  minWidth: 'max-content',
  justifyContent: 'flex-start',
  '&.MuiTableCell-body': {
    ...typography.body2,
  },
}));

export const TableRow = styled(MUITableRow)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.grey['100'],
  borderBottom: `${theme.spacing(0.5)} solid transparent`,
}));
