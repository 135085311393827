import { Tooltip as MUITooltip, Box } from '@mui/material';
import { MouseEvent } from 'react';
import { TooltipProps } from './interfaces';

export default function Tooltip({
  children,
  variant = 'dark',
  componentsProps,
  sx,
  ...rest
}: TooltipProps) {
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <MUITooltip
      componentsProps={{
        popper: { onClick },
        tooltip: { variant },
        arrow: { variant },
        ...componentsProps,
      }}
      {...rest}
    >
      <Box component="span" sx={sx}>
        {children}
      </Box>
    </MUITooltip>
  );
}

Tooltip.defaultProps = {
  placement: 'top',
};
