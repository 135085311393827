import { IconButton } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { useIntl } from 'react-intl';
import { userManagementTexts } from 'i18n';
import UserManagementIcon from 'components/Icons/custom/UserManagementIcon';
import theme from 'styles/theme';
import { NavLink } from 'react-router-dom';
import { URL_USER_MANAGEMENT } from 'constants/urls';

export default function UserManagementButton() {
  const { formatMessage } = useIntl();

  return (
    <NavLink to={URL_USER_MANAGEMENT} key={URL_USER_MANAGEMENT}>
      <Tooltip title={formatMessage(userManagementTexts.title)}>
        <IconButton>
          <UserManagementIcon sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </Tooltip>
    </NavLink>
  );
}
