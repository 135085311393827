import { IconButton } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { commonTexts, userManagementTexts } from 'i18n';
import { FormattedMessage } from 'react-intl';
import { StyledLogIcon } from './ChangelogDialog.styled';

export default function ShowChangelogButton({
  showChangelogDialog,
  type = 'scorecards',
}: {
  showChangelogDialog: () => void;
  type?: 'scorecards' | 'userManagement';
}) {
  const changelogTooltipText =
    type === 'scorecards'
      ? commonTexts.openChangelog
      : userManagementTexts.openChangelog;

  return (
    <Tooltip
      placement="top"
      title={<FormattedMessage {...changelogTooltipText} />}
      enterDelay={1000}
    >
      <IconButton
        size="small"
        sx={{ marginLeft: 0.5 }}
        onClick={showChangelogDialog}
      >
        <StyledLogIcon />
      </IconButton>
    </Tooltip>
  );
}
