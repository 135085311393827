import PageContainer, {
  PageHeader,
  PageTitle,
  PageTitleWrapper,
} from 'components/PageContainer';
import { userManagementTexts } from 'i18n';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { getUsers, selectUserMgmtLoading } from 'store/userMgmt';
import { useSelector } from 'react-redux';
import ContentLoader from 'components/ContentLoader';
import { usePageAccessible } from 'hooks';
import { AppRouteComponentProps } from 'components/AppRoutes';
import Filters from './Filters';
import UserManagementTable from './UserManagementTable';

export default function UserManagement({
  pageProps,
}: {
  pageProps: AppRouteComponentProps;
}) {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectUserMgmtLoading);
  const hasAccess = usePageAccessible(pageProps);

  useEffect(() => {
    dispatch(getUsers({ loadMore: false }));
  }, [dispatch]);

  return (
    <PageContainer hasAccess={hasAccess}>
      <PageHeader
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitleWrapper>
          <PageTitle>
            <FormattedMessage {...userManagementTexts.title} />
          </PageTitle>
        </PageTitleWrapper>
        <Filters />
      </PageHeader>
      {loading ? <ContentLoader /> : <UserManagementTable />}
    </PageContainer>
  );
}
