import { useNodeId, useNodes } from 'reactflow';
import { useDispatch } from 'react-redux';
import { openDetailsDrawer } from 'store/roadmap';
import { Stack, Typography } from '@mui/material';
import Label from 'components/Label';
import { NodeTypesEnum } from 'constants/roadmap';
import type { NodeData } from 'store/interfaces';
import { NodeContentStyled } from './NodeContent.styled';

const MAX_TAGS = 3;

interface Props {
  id?: string;
  type: string;
  data: NodeData;
}
export default function NodeContent({ id, data, type }: Props) {
  const dispatch = useDispatch();

  const nodeId = useNodeId() || id;
  const nodes = useNodes<NodeData>();

  const handleOpenDrawer = (): void => {
    const node = nodes.find((item) => item.id === nodeId);
    if (node) {
      dispatch(openDetailsDrawer(node));
    }
  };

  const isRootNode = type === NodeTypesEnum.ROOT;
  const tags = data.tags?.length ? data.tags.slice(0, MAX_TAGS) : [];

  return (
    <NodeContentStyled
      onClick={handleOpenDrawer}
      sx={(theme) => ({
        background: isRootNode ? theme.palette.primary.main : 'transparent',
        color: isRootNode ? theme.palette.common.white : undefined,
      })}
    >
      <Typography variant="heading3" sx={{ marginBottom: 0.5 }}>
        {data.title}
      </Typography>

      <Stack direction="row" flexWrap="wrap" sx={{ pointerEvents: 'none' }}>
        {tags.map((tag) => (
          <Label
            key={tag}
            label={tag}
            sx={(theme) => ({
              margin: '0 0.5rem 0.5rem 0',
              color: theme.palette.text.primary,
              background: theme.palette.grey.A300,
            })}
          />
        ))}

        {data.tags && tags.length < data.tags.length && (
          <Label
            label="..."
            sx={(theme) => ({
              margin: '0 0.5rem 0.5rem 0',
              color: theme.palette.text.primary,
              background: theme.palette.grey.A300,
            })}
          />
        )}
      </Stack>
    </NodeContentStyled>
  );
}
