import { Button, styled, MenuItem, Box, Stack } from '@mui/material';
import { UnitedReactHookFormFieldProps } from 'components/ReactHookFormField';
import blackListProps from 'styles/helpers/blackListProps';
import { InfoTooltip } from 'components/Tooltip';

export const DeleteButtonWrapper = styled(Box)<{ withDivider?: boolean }>(
  ({ theme, withDivider = true }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    '&:before': withDivider
      ? {
          content: '"|"',
          padding: `0 ${theme.spacing(1)}`,
          color: theme.palette.grey.A400,
        }
      : {},
  }),
);

export const FieldHolderWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&:last-of-type': {
    marginBottom: 0,
  },
  '&.assessmentScale': {
    minWidth: 203,
    maxWidth: 203,
  },
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
  },
  '&.Mui-disabled': {
    '.MuiSvgIcon-root': { color: theme.palette.grey.A500 },
  },
}));

DeleteButton.defaultProps = {
  size: 'small',
};

export const SubGoalContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.grey['100'],
  borderRadius: '4px',
  marginBottom: theme.spacing(1),
}));

export const GoalTitlesContainer = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  maxWidth: '30%',
  minWidth: '30%',
  background: theme.palette.common.white,
  padding: `${theme.spacing(1.5)} 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
  maxHeight: 'calc(100vh - 236px)',
  minHeight: 'calc(100vh - 236px)',
  alignSelf: 'flex-start',
  position: 'sticky',
  top: '204px',
  zIndex: 999,
}));

export const GoalTitles = styled(Box)(() => ({
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  scrollBehavior: 'smooth',
}));

export const GoalDetailsContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  background: theme.palette.common.white,
  padding: '24px 16px 16px 8px',
  width: '70%',
}));

export const FormWrapper = styled(Stack)(() => ({
  flexGrow: 1,
}));

export const ScrollHider = styled(Box)(({ theme }) => ({
  height: theme.spacing(1),
  zIndex: 999,
  backgroundColor: theme.palette.grey['200'],
}));

export const FromHeaderWrapper = styled(Box)(() => ({
  alignSelf: 'flex-start',
  top: '120px',
  width: '100%',
  position: 'sticky',
  zIndex: 998,
}));

export const FormHeader = styled(Stack)(({ theme }) => ({
  height: '68px',
  maxHeight: '68px',
  padding: '0 16px',
  background: theme.palette.common.white,
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey.A400}`,
  borderRadius: '4px 4px 0 0',
  flexShrink: 0,
  justifyContent: 'space-between',
}));

FormHeader.defaultProps = {
  direction: 'row',
};

export const FormBody = styled(Stack)(() => ({
  justifyContent: 'space-between',
  flexGrow: 1,
  borderRadius: '0 0 4px 4px',
}));

FormBody.defaultProps = {
  direction: 'row',
};

export const AddSubgoalButtonContainer = styled(Stack)(() => ({
  alignItems: 'end',
}));

export const AddSubgoalButtonInNavigationContainer = styled(Stack)(
  ({ theme }) => ({
    alignItems: 'start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    maxWidth: 'fit-content',
  }),
);

export const SubgoalListItem = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(0.5),
  marginBottom: theme.spacing(0.25),
  maxWidth: '100%',
  backgroundColor: theme.palette.grey['200'],
  borderRadius: '4px',
  display: 'flex',
  '&.error .subgoal-title': {
    color: theme.palette.red['500'],
  },
  '&:hover': {
    backgroundColor: theme.palette.grey['400'],
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const GoalListItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  padding: theme.spacing(0.5),
  '&.active': {
    backgroundColor: theme.palette.grey['300'],
    '& .subgoal': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey['400'],
      },
    },
  },
  '&.error .title': {
    color: theme.palette.error.main,
  },
  '.subgoalsCounter': {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey.A500,
    marginBottom: theme.spacing(0.5),
    '&.isCustom': {
      paddingLeft: '30px',
    },
  },
}));

export const GoalListItemContainer = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
  background: theme.palette.grey['100'],
  marginRight: theme.spacing(0.5),
  '&:first-of-type': {
    borderRadius: `4px 4px 0 0`,
  },
  '&:last-of-type': {
    paddingBottom: theme.spacing(0.5),
    borderRadius: `0 0 4px 4px`,
  },
}));

export const TitleText = styled(Box)(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const TitleWithAction = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

TitleWithAction.defaultProps = {
  direction: 'row',
};

export const TitleWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  overflow: 'hidden',
}));

export const TitleName = styled('span')(({ theme }) => ({
  textOverflow: 'ellipsis',
  marginRight: theme.spacing(0.5),
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

export const TitleWeight = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  whiteSpace: 'nowrap',
}));

export const CustomGoalWrapper = styled('span')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey.A400,
    fontSize: '24px',
    marginRight: '6px',
  },
}));

export const CommonGoalMenuSubItem = styled(MenuItem)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(1),
  '&.MuiMenuItem-root': {
    padding: theme.spacing(0.75),
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}));

export const DisabledFieldContainer = styled(Box)({
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  display: 'flex',
});

export const DisabledField = styled(Box, {
  shouldForwardProp: blackListProps(['type']),
})<{ type: UnitedReactHookFormFieldProps['type'] }>(({ type, theme }) => ({
  background: theme.palette.grey['300'],
  padding: type === 'textarea' ? '9px 11px' : '7px 11px',
  lineHeight: type === 'textarea' ? '20px' : '24px',
  fontSize: 14,
  borderRadius: '4px',
  border: '1px solid #CDD3DE',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  minHeight: 40,
}));

export const DisabledFieldLabel = styled(Box)(({ theme }) => ({
  color: theme.palette.grey.A500,
  fontSize: '0.9375rem',
  lineHeight: '1.2',
  marginBottom: '5px',
}));

export const DisabledFieldLabelRequired = styled(Box)(({ theme }) => ({
  color: theme.palette.red['500'],
  fontSize: '1rem',
  marginLeft: '3px',
}));

export const TotalGoalsWeightCounterMessage = styled(Box, {
  shouldForwardProp: blackListProps(['hasError']),
})<{ hasError: boolean }>(({ theme, hasError }) => ({
  ...theme.typography.subheadingSmall,
  marginRight: theme.spacing(2),
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  '.weight': {
    marginLeft: theme.spacing(0.75),
    color: theme.palette.grey.A500,
    '.delimiter': {
      color: theme.palette.common.black,
    },
    '.error': {
      color: hasError ? theme.palette.error.main : theme.palette.grey.A500,
    },
    '.correct': {
      color: theme.palette.common.black,
    },
    '.zero': {
      color: hasError ? theme.palette.error.main : theme.palette.grey.A500,
    },
  },
  '.MuiSvgIcon-root': {
    fontSize: 22,
    marginRight: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));

export const GoalWithSubgoalsWrapper = styled(Stack)(() => ({
  flexGrow: 1,
  overflow: 'auto',
}));

export const StyledInfoTooltip = styled(InfoTooltip)(() => ({
  marginLeft: '10px',
  height: 22,
  display: 'flex',
  alignItems: 'center',
}));

export const SelfAssessmentLabel = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  marginLeft: 8,
}));
