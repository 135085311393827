import { Color } from '@mui/material';

declare module '@mui/material' {
  interface Color {
    A300: string;
    A500: string;
    A900: string;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    blue: Pick<Color, 100 | 200 | 300 | 400 | 500 | 600 | 700>;
    purple: Pick<Color, 300 | 400 | 500 | 600 | 700>;
    red: Pick<Color, 100 | 200 | 300 | 400 | 500 | 600>;
    green: Pick<Color, 200 | 300 | 400 | 500>;
    orange: Pick<Color, 400 | 500>;
    yellow: Pick<Color, 400 | 500>;
  }
  interface PaletteOptions {
    blue: Pick<Color, 100 | 200 | 300 | 400 | 500 | 600 | 700>;
    purple: Pick<Color, 300 | 400 | 500 | 600 | 700>;
    red: Pick<Color, 200 | 300 | 400 | 500 | 600>;
    green: Pick<Color, 200 | 300 | 400 | 500>;
    orange: Pick<Color, 400 | 500>;
    yellow: Pick<Color, 400 | 500>;
  }
  interface CommonColors {
    blackInitial: string;
  }
}

const yellow = {
  400: '#FEE687', // Yellow Pale
  500: '#FFC802', // Yellow
};

const orange = {
  400: '#FCA153', // Orange Pale
  500: '#FE7C0E', // Orange
};

const green = {
  200: '#E2F8ED', // Green Gentle
  300: '#D2F7E4', // Green Gentle Pale
  400: '#A5EFC9', // Green Pale
  500: '#00E687', // Green
};

const red = {
  100: '#FFCCCC', // Red Pale
  200: '#FBE2E2', // Red Gentle
  300: '#FDECEC', // Red Pale
  400: '#FF4949', // Red Hover
  500: '#FF0D0D', // Red
  600: '#E50B0B', // Red Pressed
};

const grey = {
  100: '#FAFAFA', // Grey 100
  200: '#F4F4F4', // Grey 200
  300: '#EAEAEA', // Grey 300
  400: '#D3D3D3', // Grey 400
  500: '#BCBCBC', // Grey 500
  600: '#959595', // Grey 600
  700: '#747474', // Grey 700
  800: '#505050', // Grey 800
  900: '#323232', // Grey 900
  A100: '#E3EEF1', // Cyan Airy
  A200: '#C8D7DA', // Cyan Calm
  A300: '#E6E9EF', // Light Snow
  A400: '#CCD3DF', // Dark Snow
  A500: '#768691', // Grey Asphalt,
  A900: '#1D232A', // BG Dark Grey,
  B200: '#BFBFBF', // Grey 2
};

const blue = {
  100: '#E3EDF9', // Blue Pale
  200: '#CEE1FA', // Blue Gentle
  300: '#B0CFFF', // Blue Outline
  400: '#4197FF', // Blue Hover
  500: '#2085FF', // Blue
  600: '#1C77E6', // Blue Pressed
  700: '#BEE6EC', // Aquamarine Pale
};

const purple = {
  300: '#DBC8F6', // Purple Pale
  400: '#8531FF', // Purple Hover
  500: '#781AFF', // Purple
  600: '#6616D9', // Purple Pressed
  700: '#1e0640', // Purple Dark
};

const blackAlternative = '#010203'; // Black Alternative (Rich)
const black = '#010203'; // Black
const white = '#FFFFFF'; // White

export default {
  mode: 'light',
  primary: {
    main: blue['500'],
    light: blue['400'],
    dark: blue['600'],
  },
  secondary: {
    main: purple['500'],
    light: purple['400'],
    dark: purple['600'],
  },
  common: {
    black: blackAlternative,
    white,
    blackInitial: black,
  },
  text: {
    primary: blackAlternative,
    secondary: grey.A500,
    disabled: grey['500'],
  },
  error: {
    main: red['500'],
    light: red['400'],
    dark: red['600'],
  },
  success: {
    main: green['500'],
  },
  warning: {
    main: orange['500'],
  },
  info: {
    main: grey.B200,
  },
  grey,
  purple,
  blue,
  red,
  green,
  orange,
  yellow,
};
