// Main routes
export const URL_REVIEW_CYCLES = 'review-cycles';
export const URL_MY_SCORECARDS = 'my-scorecards';
export const URL_MY_TEAM = 'my-team';
export const URL_FEEDBACK_REQUESTS = 'feedback-requests';
export const URL_DEPARTMENT_OVERVIEW = 'department-overview';
export const URL_CAREER_ROADMAP = 'roadmap';
export const URL_CAREER_ROADMAP_SELECT = '';
export const URL_CAREER_ROADMAP_DEPARTMENT = ':departmentId';
export const URL_USER_MANAGEMENT = 'user-management';

// Sub routes
export const URL_MY_TEAM_SUBORDINATES = `subordinates`;
export const URL_MY_TEAM_DIRECT_REPORTS = `direct-reports`;
export const URL_MY_TEAM_INACTIVE_EMPLOYEES = `inactive-employees`;
export const URL_MY_TEAM_DELETED_SCORECARDS = `deleted-scorecards`;

export const URL_SCORECARD_TEMPLATES = 'scorecard-templates';

export const URL_CREATE_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/create`;
export const URL_EDIT_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/:templateId/edit`;

export const URL_EDIT_SCORECARD = ':scorecardId/edit';

export const URL_LIST = 'list';

export const URL_ACTUAL = 'actual';
export const URL_HISTORY = 'history';

export const URL_COMMON_GOALS = 'common-goals';
