import { LogoProps } from '../interfaces';

export default function LogoIcon({
  logoColor,
  textColor,
  width = 158,
}: LogoProps) {
  return (
    <svg width={width} viewBox="0 0 158 24" fill="none">
      <g clipPath="url(#clip0_404_2292)">
        <path
          d="M32.5735 15.6421C33.6475 16.7847 35.3276 17.7863 37.4981 17.7863C39.6686 17.7863 40.6252 16.6902 40.6252 15.6646C40.6252 14.2657 38.9676 13.8473 37.0784 13.357C34.5349 12.7272 31.5477 11.982 31.5477 8.69524C31.5477 6.1312 33.8114 4.15044 37.2198 4.15044C39.6475 4.15044 41.6074 4.91966 43.0544 6.31863L41.4901 8.34587C40.2762 7.13283 38.6202 6.57503 36.9852 6.57503C35.3502 6.57503 34.3484 7.34425 34.3484 8.50931C34.3484 9.67437 35.9353 10.1182 37.7794 10.583C40.3469 11.2353 43.4048 12.0285 43.4048 15.4082C43.4048 17.9948 41.5848 20.2094 37.3823 20.2094C34.5109 20.2094 32.4337 19.2078 31.0574 17.7383L32.575 15.6406L32.5735 15.6421Z"
          fill={logoColor}
        />
        <path
          d="M45.7182 17.32V10.8169H43.8516V8.67275H45.7182V6.08172L48.1684 5.11008V8.67275H50.4562V10.8169H48.1684V16.7142C48.1684 17.4834 48.5415 18.0427 49.2424 18.0427C49.7087 18.0427 50.1524 17.8568 50.3389 17.6469L50.9225 19.4882C50.4788 19.908 49.7553 20.2109 48.6122 20.2109C46.7215 20.2109 45.7182 19.1853 45.7182 17.3215"
          fill={logoColor}
        />
        <path
          d="M60.0105 18.3921C59.1471 19.5107 57.84 20.2109 56.393 20.2109C53.4991 20.2109 51.3752 18.0202 51.3752 14.3136C51.3752 10.607 53.475 8.39385 56.393 8.39385C57.7934 8.39385 59.1231 9.04611 60.0105 10.2352V8.67425H62.4607V19.9305H60.0105V18.3921ZM60.0105 12.099C59.451 11.2593 58.2597 10.5845 57.0925 10.5845C55.1551 10.5845 53.9187 12.123 53.9187 14.3136C53.9187 16.5043 55.1551 18.0427 57.0925 18.0427C58.2597 18.0427 59.4494 17.3905 60.0105 16.5508V12.099Z"
          fill={logoColor}
        />
        <path
          d="M64.8539 8.67275H67.3041V10.3281C68.1449 9.25603 69.4987 8.41634 70.9682 8.41634C71.5232 8.41634 72 8.57528 72 8.57528V10.9999C72 10.9999 71.5353 10.7705 70.3605 10.7705C69.1858 10.7705 67.7945 11.4692 67.3041 12.2624V19.9305H64.8539V8.67275Z"
          fill={logoColor}
        />
        <path
          d="M23.9278 0C12.5926 9.83931 11.4826 9.83931 0.147405 0L0 0.146945C9.87014 11.4467 9.87014 12.5548 0 23.8531L0.147405 24C11.4826 14.1607 12.5926 14.1607 23.9278 24L24.0752 23.8531C14.2051 12.5548 14.2051 11.4467 24.0752 0.146945L23.9278 0Z"
          fill={logoColor}
        />
      </g>
      <path
        d="M94.904 20H96.813V4.659H94.076L89.292 16.343L84.531 4.659H81.794V20H83.703V7.235L88.901 20H89.683L94.904 7.235V20ZM111.409 20H113.525L107.338 4.659H104.969L98.8047 20H100.921L102.278 16.596H110.052L111.409 20ZM106.165 6.361L109.477 14.894H102.83L106.165 6.361ZM124.408 20H126.662L122.522 13.744C124.615 13.56 126.501 12.042 126.501 9.282C126.501 6.453 124.5 4.659 121.694 4.659H115.53V20H117.439V13.905H120.498L124.408 20ZM124.523 9.282C124.523 11.007 123.281 12.226 121.464 12.226H117.439V6.361H121.464C123.281 6.361 124.523 7.557 124.523 9.282ZM128.603 17.838C129.868 19.264 131.823 20.276 134.491 20.276C138.631 20.276 140.149 17.999 140.149 15.768C140.149 12.548 137.297 11.766 134.79 11.099C132.835 10.57 131.064 10.11 131.064 8.569C131.064 7.12 132.375 6.131 134.215 6.131C135.894 6.131 137.504 6.706 138.654 7.971L139.804 6.568C138.516 5.211 136.699 4.429 134.353 4.429C131.34 4.429 129.086 6.154 129.086 8.684C129.086 11.697 131.823 12.387 134.284 13.031C136.308 13.583 138.171 14.089 138.171 15.929C138.171 17.102 137.297 18.574 134.56 18.574C132.398 18.574 130.742 17.516 129.73 16.389L128.603 17.838Z"
        fill={textColor}
      />
      <defs>
        <clipPath id="clip0_404_2292">
          <rect width="72" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
