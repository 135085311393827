import { AutocompleteChangeReason, Box, List, ListProps } from '@mui/material';
import { debounce } from 'debounce';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useHideTooltipsDuringScroll } from 'hooks';
import { SearchBoxProps } from './interfaces';
import { SearchLabel, Autocomplete, RequireMark } from './SearchBox.styled';

function SearchList(props: ListProps) {
  useHideTooltipsDuringScroll('.searchBoxList');
  return <List classes={{ root: 'searchBoxList' }} {...props} />;
}

export default function SearchBox<T>({
  searchLabel,
  searchPlaceholder,
  searchThreshold = 3,
  isRequired = false,
  onSearch,
  onItemSelect,
  filterOptions = (options) => options,
  getOptionLabel,
  renderOption,
  isDisabled,
  sx,
}: SearchBoxProps<T>) {
  const [searchText, setSearchText] = useState('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [items, setItems] = useState<T[]>([]);

  const clearSearch = useCallback(() => {
    setSearchText('');
    setPrevSearchText('');
    setItems([]);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((query: string) => {
      if (!query) {
        setPrevSearchText('');
        clearSearch();
      } else if (query.length >= searchThreshold && query !== prevSearchText) {
        setPrevSearchText(query);
        onSearch(query).then((data) => setItems(data));
      }
    }, 300),
    [onSearch],
  );

  useEffect(() => {
    clearSearch();
  }, [clearSearch]);

  useEffect(() => {
    search(searchText);
  }, [search, searchText]);

  const onSearchTextChange = (event: SyntheticEvent, value: string) => {
    setSearchText(value);
  };

  const onOptionSelect = (
    event: SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'selectOption') {
      onItemSelect(value as T);
    }
    setSearchText('');
    clearSearch();
  };

  return (
    <Box sx={sx}>
      {searchLabel ? (
        <SearchLabel>
          {searchLabel}
          {isRequired && <RequireMark />}
        </SearchLabel>
      ) : null}
      <Autocomplete<T, boolean, boolean, boolean>
        freeSolo
        blurOnSelect={false}
        noOptionsText={null}
        fullWidth
        disabled={isDisabled}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        options={items}
        inputValue={searchText}
        onInputChange={onSearchTextChange}
        value={null}
        onChange={onOptionSelect}
        ListboxComponent={SearchList}
        renderOption={renderOption}
        renderInput={(params) => (
          <SearchInput
            {...params}
            placeholder={searchPlaceholder}
            InputProps={{
              ...params.InputProps,
              placeholder: searchPlaceholder,
            }}
          />
        )}
        disablePortal={false}
      />
    </Box>
  );
}
