import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import Tooltip from 'components/Tooltip';
import { useScorecardStatusTooltipMessage } from 'hooks';
import { ScorecardLabel } from './ScorecardDetails.styled';
import { ScorecardStatusLabelProps } from './interfaces';

export default function ScorecardStatusLabel({
  status,
  tooltipProps,
}: ScorecardStatusLabelProps) {
  const { formatMessage } = useIntl();
  const label = commonTexts[`status.${status}`]
    ? formatMessage(commonTexts[`status.${status}`])
    : status;
  const scorecardLabel = <ScorecardLabel status={status} label={label} />;
  const message = useScorecardStatusTooltipMessage(status);

  if (!message) {
    return scorecardLabel;
  }
  return (
    <Tooltip {...tooltipProps} title={message}>
      {scorecardLabel}
    </Tooltip>
  );
}
