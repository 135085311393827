import { useCallback, useState } from 'react';
import api from 'api';
import { API_USERS_SEARCH } from 'constants/api';
import qs from 'query-string';
import { Pagination, User } from 'store/interfaces';

const DEFAULT_PAGINATION = {
  limit: 100,
  page: 1,
};

export default function useUserSearch() {
  const [isSearching, setSearching] = useState(false);

  const search = useCallback(
    ({
      searchText,
      excludeMe,
      excludeIds,
      pagination = DEFAULT_PAGINATION,
    }: {
      searchText: string;
      excludeMe?: boolean;
      excludeIds?: number[];
      pagination?: Omit<Pagination, 'total'>;
    }) =>
      api.axios
        .get<{ list: User[]; pagination: Pagination }>(
          `${API_USERS_SEARCH}?${qs.stringify(
            {
              includeWorkRecords: 1,
              userSearchName: searchText,
              excludeMe,
              excludeIds,
              ...pagination,
            },
            { skipNull: true },
          )}`,
        )
        .then(({ data: { list } }) => list)
        .finally(() => setSearching(false)),
    [setSearching],
  );

  return {
    isSearching,
    search,
  };
}
