import { styled } from '@mui/material/styles';

import TextField from 'components/TextField';

export const DatePickerInput = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey.A400,
  },
  '.MuiInputBase-root': {
    '&.Mui-disabled': {
      background: theme.palette.grey['300'],
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: theme.palette.grey['700'],
    },
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.grey.A400,
  },
  '.MuiButtonBase-root.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey['500'],
  },
}));

export const PickersDayValueContainer = styled('span')({
  position: 'relative',
});
