export const API_MEDIA = '/api/media/avatar/';

export const API_OPTIONS = '/api/work-record/options';
export const API_LOGIN_UNIVERSAL = '/api/auth/login/universal';
export const API_LOGIN_REFRESH = '/api/auth/login/refresh';
export const API_LOGIN_SAML = '/api/auth/login/saml/init';
export const API_LOGOUT = '/api/auth/logout';
export const API_USERS_SEARCH = '/api/users/search';
export const API_VERSION = '/api/version';

export const API_REVIEW_CYCLE = '/api/review-cycle';
export const API_REVIEW_CYCLE_FULL_LIST = '/api/review-cycle/full-list';
export const API_REVIEW_CYCLES_OPTIONS =
  '/api/review-cycle/options/review-cycles';
export const API_REVIEW_CYCLE_TEMPLATES_SHORT_LIST =
  '/api/review-cycle/{id}/template-short-list';
export const API_COUNTRY_STATS = '/api/work-record/options/countries/stats';

export const API_SCORECARD_TEMPLATE = '/api/scorecard-template';
export const API_SCORECARD_TEMPLATE_PUBLISH =
  '/api/scorecard-template/{id}/publish';
export const API_SCORECARD_TEMPLATE_DUPLICATE =
  '/api/scorecard-template/{id}/duplicate';
export const API_SCORECARD_TEMPLATE_AVAIL_RECORDS =
  '/api/scorecard-template/{id}/available-work-records';
export const API_SCORECARD_TEMPLATE_RECOM_RECORDS =
  '/api/scorecard-template/{id}/recommended-work-records';
export const API_COMMON_GOAL_TEMPLATES = '/api/scorecard-template/common-goals';
export const API_COMMON_GOAL_CATEGORY =
  '/api/scorecard-template/common-goals/category';
export const API_COMMON_GOALS_BY_CATEGORY_ID =
  '/api/scorecard-template/common-goals/category/{id}/goals';
export const API_COMMON_GOAL_CATEGORY_BY_ID =
  '/api/scorecard-template/common-goals/category/{id}';
export const API_SCORECARD_TEMPLATE_RECIPIENTS =
  '/api/scorecard-template/{id}/recipients';

export const API_SCORECARD = '/api/scorecard';
export const API_SCORECARD_DELETED = '/api/scorecard/deleted';
export const API_SCORECARD_GOALS = '/api/scorecard/goals';
export const API_SCORECARD_GOALS_ADD = '/api/scorecard/{id}/goals';
export const API_SCORECARD_ASSESSMENT_GROUPS_OPTIONS =
  '/api/scorecard/attribs/options/assessment-scale-group';
export const API_SCORECARD_ASSESSMENT_SCALES_OPTIONS =
  '/api/scorecard/attribs/options/assessment-scale/{id}';
export const API_SCORECARD_PARAM_TYPE_OPTIONS =
  '/api/scorecard/attribs/options/param-types';
export const API_SCORECARD_MULTIPLIERS_OPTIONS =
  '/api/scorecard/attribs/options/extra-multiplier';
export const API_ALL_CAREER_MENTORS_OPTIONS =
  '/api/work-record/org/career-mentor/all';
export const API_SUBORDINATE_CAREER_MENTORS_OPTIONS =
  '/api/work-record/org/career-mentor/subordinated';
export const API_SCORECARD_OWN = '/api/scorecard/own';
export const API_SCORECARD_APPLY = '/api/scorecard/{id}/apply';
export const API_SCORECARD_DELETE = '/api/scorecard/{id}';
export const API_SCORECARD_SEARCH = '/api/scorecard/search';

export const API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION =
  '/api/scorecard/feedback/request/{id}/resend-notification';

export const API_SCORECARD_FULL_INFO = '/api/scorecard/{id}/full';

export const API_SCORECARD_FEEDBACK_MODERATE =
  '/api/scorecard/{id}/feedback/moderate';

export const API_SCORECARD_REVIEWERS = '/api/scorecard/{id}/reviewers';
export const API_SCORECARD_RECCOMENDED_REVIEWERS =
  '/api/scorecard/{id}/reviewers/recommended';

export const API_FEEDBACK_REQUEST_OWN = '/api/scorecard/feedback/request/own';
export const API_FEEDBACK_REQUEST_OWN_HISTORY =
  '/api/scorecard/feedback/request/own/history';

export const API_SCORECARD_FEEDBACK_UPDATE = '/api/scorecard/feedback/{id}';

export const API_SCORECARD_FEEDBACK_RESET =
  '/api/scorecard/feedback/{id}/reset';

export const API_SCORECARD_AUDIT_LOGS = '/api/scorecard/{id}/audit-logs';

export const API_SUPPORT_REQUEST = '/api/support/request';

export const API_CUSAT_OPTIONS_PROJECTS_SHORT =
  '/api/cusat/options/projects/short';
export const API_CUSAT_SCORECARD_ALLOCATED_PROJECTS =
  '/api/cusat/allocated-projects/scorecard/bulk';

export const API_USER_SUMMARY_OWN = '/api/user/summary/own';

export const API_WEBSOCKET_PATH = '/api/sock';
export const WEBSOCKET_NAMESPACE = '/notifications';

export const API_CAREER_ROADMAP_SCHEMA = '/api/career/roadmap/schema';

export const API_USER_MANAGEMENT_SEARCH = '/api/users/management/search';
export const API_USER_MANAGEMENT_ASSIGN_ROLES =
  '/api/users/management/{id}/roles';
export const API_USER_MANAGEMENT_AUDIT_LOGS =
  '/api/users/management/audit-logs';

export const API_ROLES_LIST = '/api/roles';
export const API_USER_PERMISSIONS = '/api/permissions/acl';
export const API_CAREER_ROADMAP = '/api/career/roadmap';
export const API_MAILING_SETTINGS = '/api/test/mailing-settings';
