import { styled, Box, Typography, CircularProgress } from '@mui/material';
import { InfoIcon } from 'components/Icons';

export const Icon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
}));

export const VersionsWrapper = styled(Box)({
  padding: '0 8px',
  minWidth: 160,
});

export const VersionInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
}));

export const Progress = styled(CircularProgress)({
  marginLeft: 10,
});
