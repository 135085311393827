import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { LastChange } from 'store/interfaces';

export default function LastUpdateInfo({
  doneAt,
  actor,
  type = 'scorecards',
}: LastChange & { type?: 'scorecards' | 'userManagement' }) {
  const { formatDate, formatMessage } = useIntl();
  const isUserManagement = type === 'userManagement';
  if (!doneAt) {
    return <Box />;
  }

  const name = actor
    ? actor.fullName
    : formatMessage({
        id: 'pages.scorecards.marsSystem',
        defaultMessage: 'MARS system',
      });

  return (
    <Typography
      variant="body3"
      color={(theme) =>
        isUserManagement ? theme.palette.common.black : theme.palette.grey.A500
      }
    >
      {type === 'scorecards' ? (
        <FormattedMessage
          id="pages.scorecards.lastUpdated"
          defaultMessage="Last updated: {date} at {hour} by {name}"
          values={{
            name,
            date: formatDate(doneAt, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }),
            hour: formatDate(doneAt, {
              hour: 'numeric',
              minute: 'numeric',
            }),
          }}
        />
      ) : (
        <FormattedMessage
          id="pages.userManagement.lastUpdated"
          defaultMessage="Last updated: {date} by {name}"
          values={{
            name,
            date: formatDate(doneAt, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }),
          }}
        />
      )}
    </Typography>
  );
}
