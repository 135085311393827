import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import { useIntl } from 'react-intl';
import { MailIcon } from 'components/Icons';
import { useDialogState, useUserPermissions } from 'hooks';
import isNotProdEnv from 'helpers/env/isNotProdEnv';
import { useAppDispatch } from 'store';
import { getMailingSettings, selectMailingSettings } from 'store/profile';
import TechnicalAdminDialog from './MailingSettingsDialog.component';
import { MailingSettingsIconButton } from './MailingSettings.styled';

export default function MailingSettingsButton() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { canDevDebug } = useUserPermissions();

  const mailingSettings = useSelector(selectMailingSettings);
  const [isDialogOpen, onIconClick, onDialogClose] = useDialogState();
  const isButtonShown = canDevDebug && isNotProdEnv;

  useEffect(() => {
    if (isButtonShown) {
      dispatch(getMailingSettings());
    }
  }, [dispatch, isButtonShown]);

  if (!isButtonShown) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={formatMessage({
          id: 'technicalAdmin.redirectNotification',
          defaultMessage: 'Redirect Email Notification',
        })}
      >
        <MailingSettingsIconButton onClick={onIconClick}>
          <MailIcon color={mailingSettings?.enabled ? 'error' : 'inherit'} />
        </MailingSettingsIconButton>
      </Tooltip>
      {isDialogOpen && (
        <TechnicalAdminDialog
          email={mailingSettings?.redirectToEmail}
          isEmailEnabled={mailingSettings?.enabled}
          onClose={onDialogClose}
        />
      )}
    </>
  );
}
