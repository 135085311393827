import { Handle } from 'reactflow';
import { styled } from '@mui/material';

export const HandlersStyled = styled(Handle)(({ theme }) => ({
  '&.react-flow__handle': {
    width: '12px',
    height: '12px',
    borderWidth: '2px',
    background: theme.palette.grey.A500,
    transformOrigin: 'center',
    transition: '0.1s ease-in-out',

    '&.source': {
      background: theme.palette.primary.main,
    },

    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
}));
