import { clear } from 'redux-localstorage-simple';
import { REDUX_STATE_NAMESPACE } from 'constants/reduxLocalstorage';
import { IS_FEEDBACK_HIGHLIGHTED, LAST_USER_ID } from 'constants/app';
import { clearFilters } from 'store/scorecards';
import { ThunkDispatch } from '@reduxjs/toolkit';

export const clearLSUserData = (
  userId: number | null,
  dispatch: ThunkDispatch<unknown, unknown, any>,
) => {
  const lastUserId = localStorage.getItem(LAST_USER_ID);
  if (String(lastUserId) !== String(userId)) {
    clear({ namespace: REDUX_STATE_NAMESPACE });
    dispatch(clearFilters({ store: 'scorecardsDirect' }));
    dispatch(clearFilters({ store: 'scorecardsSubordinates' }));
    dispatch(clearFilters({ store: 'scorecardsOwn' }));
    dispatch(clearFilters({ store: 'scorecardsDepartment' }));
    localStorage.removeItem(IS_FEEDBACK_HIGHLIGHTED);
    localStorage.setItem(LAST_USER_ID, String(userId));
  }
};
