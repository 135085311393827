import {
  updateRoadmap,
  selectCanvasData,
  selectCurrentFilter,
  openEditDrawer,
  selectCurrentSeniority,
} from 'store/roadmap';
import { useSelector } from 'react-redux';
import {
  type Edge,
  type Node,
  type EdgeChange,
  type Connection,
  type NodeChange,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { RoadmapEvents } from 'constants/roadmap';
import {
  NewNode,
  downloadJson,
  NodeNormalizer,
  UpdatedNodeBuilder,
  EdgeNormalizer,
  applyFilters,
} from 'helpers';
import { cloneDeep } from 'lodash-es';
import { useAppDispatch } from 'store';
import type { CanvasData } from 'store/interfaces';
import { deleteNode } from 'helpers/roadmap';
import useSubscribe from './useSubscribe';

type OnChange<ChangesType> = (changes: ChangesType[]) => void;

interface CanvasState extends CanvasData {
  onNodesChange: OnChange<NodeChange>;
  onEdgesChange: OnChange<EdgeChange>;
  onConnect: (params: Edge | Connection) => void;
}

export default function useCanvasState(): CanvasState {
  const { fitView } = useReactFlow();
  const ref = useRef<CanvasData>({ nodes: [], edges: [] });

  const dispatch = useAppDispatch();
  const canvasData = useSelector(selectCanvasData);
  const currentFilter = useSelector(selectCurrentFilter);
  const currentSeniority = useSelector(selectCurrentSeniority);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    cloneDeep(canvasData.nodes),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    cloneDeep(canvasData.edges),
  );

  // Nodes are updated very often, so in order to avoid a large number of events subscription, we need this workaround
  ref.current.nodes = nodes;
  ref.current.edges = edges;

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      setEdges((prev) =>
        addEdge({ ...params, ...EdgeNormalizer.defaultEdgeConfig }, prev),
      );
    },
    [setEdges],
  );

  const saveCanvas = useCallback(
    async () =>
      // don't remove setTimeout
      // it should be async acton to avoid react batching
      new Promise((resolve) => {
        setTimeout(() => {
          dispatch(
            updateRoadmap(NodeNormalizer.normalizeCanvasState(ref.current)),
          )
            .unwrap()
            .then(resolve);
        }, 0);
      }),
    [dispatch],
  );

  const addNode = useCallback(
    (_: string, node: Node) => {
      setNodes((prev) => [...prev, node]);
    },
    [setNodes],
  );

  const onDeleteNode = useCallback(
    (_: string, node: Node) => deleteNode(setNodes, node),
    [setNodes],
  );

  const copyNode = useCallback(
    (_: string, id: string) => {
      setNodes((prev) => {
        const node =
          prev.find((item) => item.id === id) ?? NewNode.create().get();

        const newNode = NewNode.override(node).get();
        dispatch(openEditDrawer(newNode));

        // We need to call NewNode.override in order to create new id for node copy
        return [...prev, newNode];
      });
    },
    [setNodes, dispatch],
  );

  const editNode = useCallback(
    (_: string, node: Node) => {
      const nodeUpdater = UpdatedNodeBuilder.setNodesList(ref.current.nodes)
        .setEdges(ref.current.edges)
        .setNewNode(node)
        .build();

      setNodes(nodeUpdater.getNodes());
      setEdges(nodeUpdater.getEdges());
    },
    [setNodes, setEdges],
  );

  const exportCanvas = useCallback(
    () =>
      downloadJson(
        { schema: NodeNormalizer.normalizeCanvasState(ref.current) },
        'roadmap',
      ),
    [],
  );

  useSubscribe<Node>(RoadmapEvents.ADD_NODE, addNode);
  useSubscribe<Node>(RoadmapEvents.DELETE_NODE, onDeleteNode);
  useSubscribe<string>(RoadmapEvents.COPY_NODE, copyNode);
  useSubscribe<Node>(RoadmapEvents.EDIT_NODE, editNode);
  useSubscribe(RoadmapEvents.EXPORT, exportCanvas);
  useSubscribe(RoadmapEvents.SAVE, saveCanvas);

  useEffect(() => {
    if (currentFilter) {
      fitView();
    }
  }, [currentFilter, fitView]);

  const filteredNodes = useMemo(
    () => applyFilters(nodes, currentFilter, currentSeniority),
    [nodes, currentFilter, currentSeniority],
  );

  return {
    nodes: filteredNodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
  };
}
