import {
  DatePicker as MUIDatePicker,
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers';
import { dayjs } from 'helpers';
import classNames from 'classnames';
import { CalendarMonthIcon } from 'components/Icons';
import { Dayjs } from 'dayjs';
import { useRef } from 'react';
import { PickersDayValueContainer, DatePickerInput } from './DatePicker.styled';
import { DatePickerProps } from './interfaces';

function renderPickerDay<T extends Dayjs>(
  date: T,
  selectedDates: Array<T | null>,
  pickersDayProps: PickersDayProps<T>,
) {
  const dateObj = dayjs(date);
  const dayValue = dateObj.date();
  const isWeekend = dateObj.day() === 0 || dateObj.day() === 6;

  return (
    <PickersDay
      {...pickersDayProps}
      className={classNames(pickersDayProps.className, {
        'MuiPickersDay-weekend': isWeekend,
      })}
    >
      <PickersDayValueContainer>{dayValue}</PickersDayValueContainer>
    </PickersDay>
  );
}

function DatePicker({
  onChange,
  size,
  error,
  helperText,
  disabled,
  required,
  fullWidth,
  components = {},
  ...rest
}: DatePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MUIDatePicker
      {...rest}
      onChange={(val) => onChange(val ? dayjs(val).utc().format() : val)}
      renderDay={renderPickerDay}
      components={{
        ...components,
        OpenPickerIcon: CalendarMonthIcon,
      }}
      PopperProps={{
        anchorEl: inputRef.current,
        placement: 'bottom-start',
      }}
      PaperProps={{
        sx: () => ({
          '.PrivatePickersSlideTransition-root': {
            minHeight: 'unset',
            '&>div[role="grid"]': {
              mb: 1,
            },
          },
        }),
      }}
      renderInput={(params) => (
        <DatePickerInput
          {...params}
          error={error || params.error}
          helperText={helperText}
          required={required}
          size={size}
          fullWidth={fullWidth}
          ref={inputRef}
        />
      )}
    />
  );
}

DatePicker.defaultProps = {
  views: ['year', 'month', 'day'],
  reduceAnimations: true,
  showDaysOutsideCurrentMonth: true,
};

export default DatePicker;
