import { FormattedMessage } from 'react-intl';
import { GoalParam, SelfAssessmentEnum } from 'store/interfaces';
import { formTexts } from 'i18n';
import { BoldLi } from './ChangelogDialog.styled';

const getMessage = (message: { id: string; defaultMessage: string }) => (
  <BoldLi>
    <FormattedMessage {...message} />
  </BoldLi>
);

// Because of the way we show self assessment setup with radio buttons, we need to show different messages for different changes.
export default function SelfAssessmentSetupChangelogItem({
  params,
  changedParam,
}: {
  params: GoalParam[];
  changedParam: GoalParam;
}) {
  const { include, enable } = params.reduce(
    (
      acc: {
        include: GoalParam | null;
        enable: GoalParam | null;
      },
      param,
    ) => {
      if (param.name === SelfAssessmentEnum.INCLUDE) {
        acc.include = param;
      }
      if (param.name === SelfAssessmentEnum.ENABLE) {
        acc.enable = param;
      }
      return acc;
    },
    {
      include: null,
      enable: null,
    },
  );
  // show disable self assessment message
  if (
    changedParam.name === SelfAssessmentEnum.ENABLE &&
    enable &&
    !changedParam.payload?.value
  ) {
    return getMessage(formTexts.disableSelfAssessment);
  }
  // show calculate self assessment message
  if (
    include &&
    changedParam.payload.value &&
    changedParam.name === SelfAssessmentEnum.INCLUDE
  ) {
    return getMessage(formTexts.calculateSelfAssessment);
  }
  // show enable self assessment message
  if (
    (SelfAssessmentEnum.ENABLE && changedParam.payload.value) ||
    (changedParam.name === SelfAssessmentEnum.INCLUDE &&
      !include?.payload.value)
  ) {
    // prevent duplicate message
    if (
      include?.payload.value ||
      (enable?.payload.value === false && include?.payload.value === false)
    ) {
      return null;
    }
    return getMessage(formTexts.enableSelfAssessment);
  }

  return null;
}
