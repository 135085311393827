import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SupportFeedbackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18 3L17 5L8 8H4C2.9 8 2 8.9 2 10V14C2 15.1 2.9 16 4 16H6V21H8V16L17 19L18 21H20V13.7227C20.5956 13.3764 21 12.7389 21 12C21 11.2611 20.5956 10.6236 20 10.2773V3H18ZM18 6.77539V17.2246L17.6328 17.1016L10 14.5586V9.44141L17.6328 6.89844L18 6.77539ZM4 10H8V14H6H4V10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
