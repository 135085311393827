import { ChangeGroup } from 'store/interfaces';
import { FormattedMessage } from 'react-intl';
import { scorecardsTexts } from 'i18n';

export default function FeedbackUpdateChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  const { dataChangePayload } = changeGroups[0];
  const { feedbackGoalHeaders } = dataChangePayload!;
  const { goalName, payload: goalPayload } = feedbackGoalHeaders[0];
  const { commentFinalized, feedbackSubgoalHeaders } = goalPayload;

  return (
    <>
      <FormattedMessage
        {...scorecardsTexts.updatedGoalDescription}
        values={{
          name: goalName,
          br: <br />,
          i: (chunks) => <i>{chunks}</i>,
        }}
      />
      <ul>
        {feedbackSubgoalHeaders.map(({ id, subGoalName, payload }: any) => (
          <li key={id}>
            {subGoalName}
            <ul>
              <li>
                {payload.assessmentScoreFinilized?.name ||
                  payload.assessmentScoreOriginal?.name ||
                  payload.commentFinalized ||
                  payload.commentOriginal}
              </li>
            </ul>
          </li>
        ))}
      </ul>
      {commentFinalized && (
        <>
          <FormattedMessage
            id="pages.scorecards.comment"
            defaultMessage="Comment:"
          />
          <br />
          {commentFinalized}
        </>
      )}
    </>
  );
}
