import { ChangeGroup } from 'store/interfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import { scorecardsTexts } from 'i18n';

export default function FeedbackModerateChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  const { formatMessage } = useIntl();
  const { dataChangePayload, previousState } = changeGroups[0];
  const currentGoal = dataChangePayload?.feedbackGoalHeaders[0] || {};
  const previousGoal = previousState?.feedbackGoalHeaders[0] || {};
  const { goalName } = currentGoal;
  const user = dataChangePayload?.user;

  const fields = [];

  if (Object.hasOwn(currentGoal.payload, 'commentFinalized')) {
    fields.push({
      field: formatMessage(scorecardsTexts.finalizedComment),
      prev: previousGoal.previousState?.commentFinalized,
      curr: currentGoal.payload?.commentFinalized,
      fieldName: 'commentFinalized',
    });
  }
  if (
    currentGoal.payload?.feedbackSubgoalHeaders &&
    previousGoal.previousState?.feedbackSubgoalHeaders
  ) {
    if (
      currentGoal.payload?.feedbackSubgoalHeaders[0].payload?.commentFinalized
    ) {
      fields.push({
        field: formatMessage(
          user
            ? scorecardsTexts.assessmentScoreFrom
            : scorecardsTexts.assessmentScore,
          user,
        ),
        prev:
          previousGoal.previousState?.feedbackSubgoalHeaders[0].previousState
            ?.commentFinalized ?? '-',
        curr:
          currentGoal.payload?.feedbackSubgoalHeaders[0].payload
            ?.commentFinalized ?? '-',
        subGoalName: currentGoal.payload?.feedbackSubgoalHeaders[0].subGoalName,
        fieldName: 'commentFinalized',
      });
    } else {
      fields.push({
        field: formatMessage(
          user
            ? scorecardsTexts.assessmentScoreFrom
            : scorecardsTexts.assessmentScore,
          user,
        ),
        prev:
          previousGoal.previousState?.feedbackSubgoalHeaders[0].previousState
            ?.assessmentScoreFinalized?.name ?? '-',
        curr:
          currentGoal.payload?.feedbackSubgoalHeaders[0].payload
            ?.assessmentScoreFinalized?.name ?? '-',
        subGoalName: currentGoal.payload?.feedbackSubgoalHeaders[0].subGoalName,
        fieldName: 'assessmentScoreFinalized',
      });
    }
  }

  return (
    <>
      <FormattedMessage
        {...scorecardsTexts.updatedGoalDescription}
        values={{ name: goalName, br: <br />, i: (chunks) => <i>{chunks}</i> }}
      />
      {fields.map(({ field, prev, curr, subGoalName, fieldName }) => (
        <div key={field}>
          {subGoalName && (
            <>
              <FormattedMessage
                {...scorecardsTexts.updatedSubgoalDescription}
                values={{
                  name: subGoalName,
                  br: <br />,
                  i: (chunks) => <i>{chunks}</i>,
                }}
              />
              <br />
            </>
          )}
          {fieldName === 'commentFinalized' ? (
            <>
              <strong>
                {formatMessage(
                  user
                    ? scorecardsTexts.savedCommentFrom
                    : scorecardsTexts.savedComment,
                  user,
                )}
              </strong>
              <br />
              <i>{prev}</i>
              <br />
              <strong>
                {formatMessage(
                  user
                    ? scorecardsTexts.updatedCommentFrom
                    : scorecardsTexts.updatedComment,
                  user,
                )}
              </strong>
              <br />
              <i>{curr}</i>
            </>
          ) : (
            <FormattedMessage
              {...scorecardsTexts.updatedField}
              values={{
                field,
                prev,
                curr,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          )}
        </div>
      ))}
    </>
  );
}
