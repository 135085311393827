import { ChangeGroup } from 'store/interfaces';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { Box } from '@mui/material';
import { scorecardsTexts } from 'i18n';

function FeedbackRequestChangelogItem({
  goals,
  userFullName,
}: {
  userFullName: string;
  goals: { id: number; type: string; goalName: string }[];
}) {
  const text =
    goals[0].type === 'delete'
      ? scorecardsTexts.deleteAsReviewer
      : scorecardsTexts.setAsReviewer;

  return (
    <Box mb={1}>
      <FormattedMessage {...text} values={{ userFullName }} />
      <ul>
        {goals.map((g: any) => (
          <li key={g.id}>{g.goalName}</li>
        ))}
      </ul>
    </Box>
  );
}

export default function FeedbackRequestChangelog({
  changeGroups,
}: {
  changeGroups: ChangeGroup[];
}) {
  return (
    <>
      {changeGroups.map((cg, i) => {
        const result = [];
        const { dataChangePayload, previousState } = cg;

        if (dataChangePayload) {
          result.push(
            <FeedbackRequestChangelogItem
              userFullName={dataChangePayload.user.fullName}
              goals={dataChangePayload.requestGoals}
            />,
          );
        }

        if (previousState) {
          result.push(
            <FeedbackRequestChangelogItem
              userFullName={previousState.user.fullName}
              goals={previousState.requestGoals}
            />,
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            {result.map((r, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>{r}</Fragment>
            ))}
          </Fragment>
        );
      })}
    </>
  );
}
