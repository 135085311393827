import { IconButton, InputAdornment, TextField } from '@mui/material';
import { CloseIcon, SearchIcon } from 'components/Icons';
import { TextFieldProps } from 'components/TextField';
import { ChangeEvent } from 'react';
import { SearchField } from './SearchInput.styled';

export default function SearchInput(
  props: TextFieldProps & { slim?: boolean },
) {
  const { value, onChange, InputProps, slim } = props;
  const clearSearch = () =>
    onChange &&
    onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);

  const SearchComponent = slim ? SearchField : TextField;

  return (
    <SearchComponent
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
