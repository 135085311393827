import { SyntheticEvent, useState } from 'react';

export default function useMenuAnchor() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const onMenuClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    closeMenu();
  };
  const open = Boolean(anchorEl);
  return {
    open,
    anchorEl,
    openMenu,
    closeMenu,
    onMenuClick,
  };
}
