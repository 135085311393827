import { TextField, styled } from '@mui/material';

export const SearchField = styled(TextField)(() => ({
  '& .MuiInputAdornment-positionStart .MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '6px 8px 6px 0px',
    lineHeight: '20px',
  },
}));
