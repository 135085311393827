import { ChangeGroup, Role, ChangelogUser } from 'store/interfaces';
import { FormattedMessage } from 'react-intl';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { commonTexts, userManagementTexts } from 'i18n';
import { useSelector } from 'react-redux';
import { selectOptions } from 'store/options';
import { ROLE_DEPARTMENT_MANAGER } from 'constants/roles';

function RoleParams({
  params,
  oldParams,
}: {
  params: string;
  oldParams?: string;
}) {
  const { units, locations } = useSelector(selectOptions);
  const parsedParams = JSON.parse(params);
  const parsedOldParams = oldParams ? JSON.parse(oldParams) : null;
  const countries = parsedParams['country-scoping'].map(
    (id: number) => locations.find((location) => id === location.id)?.name,
  );
  const departments = parsedParams['department-scoping'].map(
    (id: number) => units.find((unit) => id === unit.id)?.name,
  );
  const oldCountries = parsedOldParams?.['country-scoping'].map(
    (id: number) => locations.find((location) => id === location.id)?.name,
  );
  const oldDepartments = parsedOldParams?.['department-scoping'].map(
    (id: number) => units.find((unit) => id === unit.id)?.name,
  );
  const allCountriesText = (
    <FormattedMessage {...commonTexts.allStarLocations} />
  );
  const allDepartmentsText = (
    <FormattedMessage {...commonTexts.allStarDepartments} />
  );

  const newCountriesText = countries.length
    ? countries.join(', ')
    : allCountriesText;
  const newDepartmentsText = departments.length
    ? departments.join(', ')
    : allDepartmentsText;

  const oldCountriesText = oldCountries?.length
    ? oldCountries.join(', ')
    : allCountriesText;
  const oldDepartmentsText = oldDepartments?.length
    ? oldDepartments.join(', ')
    : allDepartmentsText;

  return (
    <ul>
      <li>
        <FormattedMessage {...userManagementTexts.availableDepartments} />{' '}
        {parsedOldParams ? (
          <>
            <b>{oldDepartmentsText}</b>
            {' → '}
          </>
        ) : null}
        <b>{newDepartmentsText}</b>
      </li>
      <li>
        <FormattedMessage {...userManagementTexts.availableCountries} />{' '}
        {parsedOldParams ? (
          <>
            <b>{oldCountriesText}</b>
            {' → '}
          </>
        ) : null}
        <b>{newCountriesText}</b>
      </li>
    </ul>
  );
}

export default function UserRolesUpdateChangelog({
  changeGroups,
  user,
  role: changedRole,
}: {
  changeGroups: ChangeGroup[];
  user?: ChangelogUser;
  role?: Role;
}) {
  const addedRoles = changeGroups
    .filter((changeGroup) => changeGroup.dataChangePayload?.role)
    .map((changeGroup, index) => ({
      ...changeGroup.dataChangePayload?.role,
      index,
    }));
  const removedRoles = changeGroups
    .filter((changeGroup) => changeGroup.previousState?.role)
    .map((changeGroup) => changeGroup.previousState?.role);
  const DMRoleParams = changeGroups.find(
    (changeGroup) => changeGroup.dataChangePayload?.params,
  )?.dataChangePayload?.params;
  const PreviousDMRoleParams = changeGroups.find(
    (changeGroup) => changeGroup.previousState?.params,
  )?.previousState?.params;

  let changedParamsContent: ReactNode | null = null;

  if (
    (!addedRoles?.find((role) => role.alias === ROLE_DEPARTMENT_MANAGER) &&
      DMRoleParams) ||
    (!addedRoles.length && !removedRoles.length && DMRoleParams)
  ) {
    changedParamsContent = (
      <div>
        <FormattedMessage
          id="pages.userManagement.changedParams"
          defaultMessage="<b>Changed options</b> for Department Manager: "
          values={{
            role: changedRole?.name,
            b: (chunks: ReactNode) => <b>{chunks}</b>,
          }}
        />
        <RoleParams params={DMRoleParams} oldParams={PreviousDMRoleParams} />
      </div>
    );
  }

  return (
    <div>
      <Stack sx={{ marginBottom: '16px' }} direction="row" alignItems="center">
        <FormattedMessage
          id="pages.userManagement.changedUser"
          defaultMessage="User: "
        />
        <i>{user?.fullName}</i>
      </Stack>
      {!!addedRoles.length && (
        <div>
          <b>
            <FormattedMessage
              id="pages.userManagement.addedRoles"
              defaultMessage="Added roles: "
            />
          </b>
          <ul>
            {addedRoles.map((role) => (
              <li key={role.name}>
                <span>{role.name}</span>
                {role.alias === ROLE_DEPARTMENT_MANAGER ? (
                  <RoleParams
                    params={changeGroups[role.index].dataChangePayload?.params}
                    oldParams={PreviousDMRoleParams}
                  />
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!removedRoles.length && (
        <div>
          <b>
            <FormattedMessage
              id="pages.userManagement.removedRoles"
              defaultMessage="Removed roles: "
            />
          </b>
          <ul>
            {removedRoles.map((role) => (
              <li key={role.name}>{role.name}</li>
            ))}
          </ul>
        </div>
      )}
      {changedParamsContent}
    </div>
  );
}
