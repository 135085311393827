export enum Permissions {
  DEV_DEBUG = 'DEV_DEBUG',
  ROLES_MANAGE = 'ROLES_MANAGE',
  ROLES_VIEW = 'ROLES_VIEW',
  COMMON_GOAL_MANAGE = 'COMMON_GOAL_MANAGE',
  CUSAT_MANAGE = 'MARS_CUSAT_MANAGE',
  CUSAT_VIEW = 'MARS_CUSAT_VIEW',
  REVIEW_CYCLE_MANAGE = 'MARS_REVIEW_CYCLE_MANAGE',
  REVIEW_CYCLE_VIEW = 'MARS_REVIEW_CYCLE_VIEW',
  SCORECARD_MANAGE = 'MARS_SCORECARD_MANAGE',
  SCORECARD_REQUEST_FEEDBACK = 'MARS_SCOREREQUEST_FEEDBACK',
  SCORECARD_LEAVE_FEEDBACK = 'MARS_SCORECARD_LEAVE_FEEDBACK',
  SCORECARD_TEMPLATE_EDIT = 'MARS_SCORECARD_TEMPLATE_EDIT',
  SCORECARD_TEMPLATE_PUBLISH = 'MARS_SCORECARD_TEMPLATE_PUBLISH',
  SCORECARD_TEMPLATE_RECIPIENTS_VIEW = 'MARS_SCORECARD_TEMPLATE_RECIPIENTS_VIEW',
  SCORECARD_VIEW = 'MARS_SCORECARD_VIEW',
  SCORECARD_TEMPLATE_VIEW = 'MARS_SCORECARD_TEMPLATE_VIEW',
  SCORECARD_TEMPLATE_COMMON_GOALS_VIEW = 'MARS_SCORETEMPLATE_COMMON_GOALS_VIEW',
  SCORECARD_DEPARTMENT_OVERVIEW = 'MARS_SCORECARD_DEPARTMENT_OVERVIEW',
  SCORECARD_MODERATE_FEEDBACK = 'MARS_SCOREMODERATE_FEEDBACK',
  SCORECARD_CUSAT_ALLOCATED_PROJECTS_MANAGE = 'MARS_SCORECARD_CUSAT_ALLOCATED_PROJECTS_MANAGE',
  SCORECARD_LIST = 'MARS_SCORECARD_LIST',
  SCORECARD_DELETE = 'MARS_SCORECARD_DELETE',
  USERS_MANAGEMENT = 'USERS_MANAGEMENT',
  CAREER_ROADMAP_VIEW = 'CAREER_ROADMAP_VIEW',
  CAREER_ROADMAP_MANAGE = 'CAREER_ROADMAP_MANAGE',
}
