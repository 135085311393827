export const LOGIN_REDIRECT_KEY = 'loginRedirect';

export const FEEDBACK_REQUEST_DATE_KEY = 'feedbackRequestDate';

export const SUPPORT_EMAIL = 'mars-support@star.global';

export const IS_LOGGED_IN = 'isLoggedIn';

export const IS_FEEDBACK_HIGHLIGHTED = 'isFeedbackHighlighted';

export const LAST_USER_ID = 'lastUserId';
export const LAST_USER_DATA = 'lastUserData';
