import {
  ChangeEventHandler,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useAppDispatch } from 'store';
import { commonTexts, userManagementTexts } from 'i18n';
import { useIntl } from 'react-intl';
import FilterBar from 'components/FilterBar';
import { Box } from '@mui/system';
import SearchInput from 'components/SearchInput';
import {
  UserManagementFilters,
  changeFilter,
  getRoles,
  getUsers,
  selectRoles,
  selectUserMgmtFilters,
  clearFilters,
} from 'store/userMgmt';
import { debounce } from 'debounce';
import { Option, UIOption } from 'store/options';
import { useSelector } from 'react-redux';
import { AutocompleteRenderOptionState } from '@mui/material';
import Autocomplete from 'components/Autocomplete';
import RoleOptionWithInfo from '../common/RoleOptionWithInfo';

export default function Filters() {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const roles = useSelector(selectRoles);
  const filters = useSelector(selectUserMgmtFilters);
  const rolesOptions = useMemo(
    () => [
      { id: 0, name: formatMessage(userManagementTexts.allRoles) },
      ...roles.map((role) => ({
        id: role.id,
        name: role.name,
        description: role.description,
      })),
    ],
    [formatMessage, roles],
  );
  const filterValue = useMemo(
    () => ({
      roleId: rolesOptions.find((r) => r.id === filters.roleIds) || null,
    }),
    [filters.roleIds, rolesOptions],
  );

  const onFilterChange = useCallback(
    (key: keyof UserManagementFilters) =>
      (e: SyntheticEvent, option: UIOption | null) => {
        const value = option?.id || null;
        dispatch(changeFilter({ key, value }));
        dispatch(getUsers({ loadMore: false }));
      },
    [dispatch],
  );
  const searchWithDelay = useMemo(
    () =>
      debounce(() => {
        dispatch(getUsers({ loadMore: false }));
      }, 300),
    [dispatch],
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    dispatch(changeFilter({ key: 'userSearchName', value }));
    searchWithDelay.clear();
    searchWithDelay();
  };
  const renderRoleOption = useCallback(
    (
      optionProps: HTMLAttributes<HTMLLIElement>,
      option: Option,
      { selected }: AutocompleteRenderOptionState,
    ) => (
      <RoleOptionWithInfo
        key={option.id}
        selected={selected}
        option={option}
        optionProps={optionProps}
        hideCheckbox
      />
    ),
    [],
  );

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <FilterBar
      showClearButton={false}
      onClearFilters={() => {}}
      filtersSx={{
        marginBottom: 0,
      }}
      sx={{
        width: 'auto',
      }}
    >
      <Box width={318}>
        <SearchInput
          id="user-search"
          value={filters.userSearchName}
          placeholder={formatMessage(commonTexts.searchEmployee)}
          size="large"
          onChange={onSearchChange}
          fullWidth
        />
      </Box>
      <Autocomplete
        size="large"
        options={rolesOptions}
        value={filterValue.roleId}
        onChange={onFilterChange('roleIds')}
        placeholder={formatMessage(userManagementTexts.filterByRole)}
        sx={{
          width: 318,
        }}
        renderOption={renderRoleOption}
      />
    </FilterBar>
  );
}
